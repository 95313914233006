import { FILLED_SURVEY_PART_ONE, FILLED_SURVEY_PART_TWO, FAILED_TO_SUBMIT_SURVEY_DATA } from '../actions/survey';

const INITIAL_STATE = {
    partOne: [],
    partTwo: [],
    message: ''
};

const surveyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILLED_SURVEY_PART_ONE:
            state = { ...state, partOne: action.payload };
            return state;
        case FILLED_SURVEY_PART_TWO:
            state = { ...state, partTwo: action.payload };
            return state;
        case FAILED_TO_SUBMIT_SURVEY_DATA:
            state = { ...state, message: action.payload };
            return state;
        default:
            return state;
    }
}

export default surveyReducer;