import {
    Vector3, 
    PointColor, 
    Animation, 
    PointsCloudSystem, 
    Texture, 
    StandardMaterial, 
    MeshBuilder,  
    SceneLoader, 
    CubeTexture, 
    Color3, 
    Engine, 
    Scene, 
    FreeCamera,
    TransformNode, 
    Matrix,
    Tools,
    DirectionalLight,
    GlowLayer,
} from '@babylonjs/core';
//import { ArcRotateCamera } from 'babylonjs/Cameras/arcRotateCamera';


export class WebGL {

    _canvas;
    _engine;
    _scene;
    _baseUrl = "./model-assets/earth-assets/";

    constructor(canvasElement) {

        // Instantiate engine.
        this._canvas = document.getElementById(canvasElement);
        this._engine = new Engine(this._canvas, true);
    }

    createScene() {

        // Instantiate scene.
        this._scene = new Scene(this._engine);
        this._scene.clearColor = new Color3.Black();
        this._scene.ambientColor = new Color3.Black();
        this._scene.useRightHandedSystem = true;

        const canvas = this._scene.getEngine().getRenderingCanvas();

        // Mobile Detect
        function isMobileDevice() {
            return (navigator.userAgent.indexOf('iPhone') != -1) || (navigator.userAgent.indexOf('Android') != -1) || (navigator.userAgent.indexOf('iPad') != -1);
        };

        let deviceType;
        if(!isMobileDevice()){
            deviceType = "desktop";
        }else{
            if(this._engine.getRenderWidth(true) > this._engine.getRenderHeight(true)){
                deviceType = "mobileLandscape";
            } else {
                deviceType = "mobilePortrait";
            }
        }

        // Add resize event listener.
        let moon, asteroid, asteroid_1, asteroid_2, asteroid_3;
        window.addEventListener('resize', () => {

            if(deviceType != "desktop"){
                if(this._engine.getRenderWidth(true) > this._engine.getRenderHeight(true)){
                    deviceType = "mobileLandscape";
                    if (camera && moon && asteroid && asteroid_1 && asteroid_2 && asteroid_3) {
                        camera.fov = 0.4;
                        moon.position = new Vector3(-1.8, 0.8, 3.5);
                        asteroid.position = new Vector3(1.75, -0.85, 5.1);
                        asteroid_1.position = new Vector3(-1.85, -0.85, 5.1);
                        asteroid_2.position = new Vector3(2.85, -0.85, 6.1);
                        asteroid_3.position = new Vector3(-3.55, -1.45, 9.1);
                    }
                } else {
                    deviceType = "mobilePortrait";
                    if (camera && moon && asteroid && asteroid_1 && asteroid_2 && asteroid_3) {
                        camera.fov = 1.0;
                        moon.position = new Vector3(-1.2, 3.5, 4.0);
                        asteroid.position = new Vector3(1.0, 2.5, 7.1);
                        asteroid_1.position = new Vector3(-1.1, 2.5, 7.1);
                        asteroid_2.position = new Vector3(2.0, 3.0, 7.1);
                        asteroid_3.position = new Vector3(-1.95, 3.0, 7.1);
                    }
                }
            }

            setTimeout( () => {
                this._engine.resize();
            }, 50);

        });

        let hdrTexture = new CubeTexture.CreateFromPrefilteredData(
            this._baseUrl + "environment_sun.env",
            this._scene
        );

        hdrTexture.setReflectionTextureMatrix(
            Matrix.RotationX(
                Tools.ToRadians(160)
            ),
            Matrix.RotationY(
                Tools.ToRadians(45)
            )
        )

        hdrTexture.gammaSpace = false;
        this._scene.environmentTexture = hdrTexture;
        this._scene.environmentIntensity = 0;

        let camera, step;

        if (deviceType == "desktop") {
            // desktop
            camera = new FreeCamera('camera_1', new Vector3(0, 1.2, -2.0),  this._scene);
            camera.setTarget(new Vector3(0, 1.05, 0));
            camera.maxZ = 1000;
            camera.minZ = 0.01;
            camera.fov = 0.4;
            camera.inertia = 0.1;

            // Moving CAMERA w/ mouse move
            step = 30000;
            document.addEventListener("mousemove", function(ev){
                if (camera.position.x < 1) {
                    camera.position.x += ev.movementX/step;
                } else if(camera.position.x > -1) {
                    camera.position.x -= ev.movementX/step;
                }

                if (camera.position.y < 2.2) {
                    camera.position.y += ev.movementY/step;
                } else if(camera.position.y > 0.2) {
                    camera.position.y -= ev.movementY/step;
                }
            });
            /*
            document.addEventListener("wheel", function(ev){
                if (ev.deltaY) {
                    if (ev.deltaY > 0 && camera.target.y > -15.05) {
                        camera.target.y -= 5;
                        camera.position.y -= 0.05;
                    } else if (ev.deltaY < 0 && camera.target.y < 1.05) {
                        camera.target.y += 5;
                        camera.position.y += 0.05;
                    }
                }
            });
            */
        } else {
            // mobile
            if (deviceType == "mobilePortrait") {
                // camera = new FreeCamera('camera_1', new Vector3(0, 0.6, -2.0),  this._scene);
                // camera.setTarget(new Vector3(0, 0.85, 0));
                camera = new FreeCamera('camera_1', new Vector3(0, 0.15, -2.0),  this._scene);
                camera.setTarget(new Vector3(0, 0.5, 0));
                camera.maxZ = 1000;
                camera.minZ = 0.01;
                camera.fov = 1.0;
                camera.inertia = 0.1;
            } else {
                camera = new FreeCamera('camera_1', new Vector3(0, 1.2, -2.0),  this._scene);
                camera.setTarget(new Vector3(0, 1.05, 0));
                camera.maxZ = 1000;
                camera.minZ = 0.01;
                camera.fov = 0.4;
                camera.inertia = 0.1;
            }

            // Moving CAMERA w/ gyroscope
            step = 0.000125;
            window.addEventListener("deviceorientation", function(ev){
                if (deviceType == "mobilePortrait") {
                    if (camera.position.x > -0.1 && ev.gamma < 0) {
                        camera.position.x -= step;
                    } else if (camera.position.x < 0.1 && ev.gamma > 0) {
                        camera.position.x += step;
                    } 
                } else {
                    if (camera.position.x > -0.1 && ev.beta < 0) {
                        camera.position.x -= step;
                    } else if (camera.position.x < 0.1 && ev.beta > 0) {
                        camera.position.x += step;
                    }    
                }
            });
  
        }   
    
        let sun = new DirectionalLight("sun", new Vector3(-1.0, 0.0, -0.75), this._scene);
        sun.intensity = 3;
        //sun.position = new Vector3(5.0, 0.0, 12.0);

        // FADE Scene
        let fade = MeshBuilder.CreateSphere("fade", { diameter: 0.25, segments: 4, sideOrientation: 2 }, this._scene);
        fade.position = camera.position;
        fade.renderingGroupId = 3;

        //GLOW LAYER
        var gl = new GlowLayer("glow", this._scene, { 
            mainTextureFixedSize: 128, // GLOW of ATMOSPHERE  64 > more / 256 > less
            blurKernelSize: 32, // EXTENSION of ATMOSPHERE 64 > less / 128 > more
            mainTextureSamples: 4 // ANTIALIASING of ATMOSPHERE 1 > LESS / 4 MORE
        });
        
        // ANIMATION SETUP
        const yRot = new Animation("yRotate", "rotation.y", 2,Animation.ANIMATIONTYPE_FLOAT,Animation.ANIMATIONLOOPMODE_CYCLE);
        const keyframes = [];

        keyframes.push({
            frame: 0,
            value: 0
        });

        keyframes.push({
            frame: 900,
            value: Math.PI * 2
        });

        keyframes.push({
            frame: 1500,
            value: Math.PI * 4
        });

        yRot.setKeys(keyframes);
        
        // EARTH Loading
        SceneLoader.ImportMesh("", this._baseUrl, "Earth_002_rdc.babylon", this._scene, function(newMeshes){
        });
    
        // CLOUDS Loading
        SceneLoader.ImportMesh("", this._baseUrl, "Clouds_002_rdc.babylon", this._scene, function(newMeshes){
        });

        // MOON Loading
        SceneLoader.ImportMesh("", this._baseUrl, "Moon_002_rdc.babylon", this._scene, function(newMeshes){
        });

        // HALO Loading
        SceneLoader.ImportMesh("", this._baseUrl, "Halo_002.babylon", this._scene, function(newMeshes){
        });

        // ASTEROID Loading
        SceneLoader.ImportMesh("", this._baseUrl, "Rock_002_rdc.babylon", this._scene, function(newMeshes){
        });
                
        this._scene.onReadyObservable.add(()=>{
        
            let CoT = new TransformNode("center");
            this._scene.meshes.forEach(element => {
                if (element.name.indexOf("Earth") !== -1){
                    element.rotationQuaternion = null;
                    element.parent = CoT;
                    sun.excludedMeshes.push(element);
                    element.visibility = 0;
                }
            });
            CoT.rotation.x = Math.PI/4;
            CoT.animations.push(yRot);
            this._scene.beginAnimation(CoT, 0, 900, true);

            let clouds = this._scene.meshes.find(mesh => mesh.id === "Clouds");
            clouds.visibility = 0;
            clouds.rotationQuaternion = null;
            clouds.material.emissiveIntensity = 0;
            gl.addExcludedMesh(clouds);
            clouds.animations.push(yRot);
            this._scene.beginAnimation(clouds, 900, 1500, true);
            sun.includedOnlyMeshes.push(clouds);

            let halo = this._scene.meshes.find(mesh => mesh.id === "Halo");
            halo.visibility = 0;
            halo.rotationQuaternion = null;
            halo.scaling = new Vector3(0.4975, 0.4975, 0.4975);
            halo.rotation.z = Tools.ToRadians(70);
            // halo.scaling = new Vector3(0.4875, 0.4875, 0.4875);
            // halo.rotation.z = Tools.ToRadians(80);
            halo.rotation.y = Tools.ToRadians(90);
            halo.material.emissiveIntensity = 0;
            halo.material.environmentIntensity = 0;
            sun.excludedMeshes.push(halo);

            moon = this._scene.meshes.find(mesh => mesh.id === "Moon");
            moon.visibility = 0;
            moon.renderingGroupId = 1;
            moon.rotationQuaternion = null;
            moon.material.metallic = 0.45;
            moon.material.roughness = 0.75;
            //moon.material.environmentIntensity = 0.05;
            moon.material.environmentIntensity = 0.05;
            moon.material.emissiveColor = new Color3(0.0, 0.4, 0.75);
            moon.material.emissiveIntensity = 0.025;
            gl.addExcludedMesh(moon);
            moon.animations.push(yRot);
            this._scene.beginAnimation(moon, 900, 0, true);
            sun.includedOnlyMeshes.push(moon);
            
            asteroid = this._scene.meshes.find(mesh => mesh.id === "Rock_02");
            asteroid.rotationQuaternion = null;
            asteroid.rotation = new Vector3(-Math.PI*2, -Math.PI/3, Math.PI*1.5);
            asteroid.scalingDeterminant = 0.10;
            asteroid.renderingGroupId = 1;
            sun.excludedMeshes.push(asteroid);

            asteroid_1 = asteroid.clone("Rock_02_1");
            asteroid_1.rotation = new Vector3(-Math.PI, -Math.PI/3, Math.PI/2);
            asteroid_1.scalingDeterminant = 0.10;
            asteroid_1.renderingGroupId = 1;
            sun.excludedMeshes.push(asteroid_1);

            asteroid_2 = asteroid.clone("Rock_02_2");
            asteroid_2.rotation = new Vector3(-Math.PI, Math.PI/3, Math.PI/2);
            asteroid_2.scalingDeterminant = 0.07;
            asteroid_2.renderingGroupId = 1;
            sun.excludedMeshes.push(asteroid_2);

            asteroid_3 = asteroid.clone("Rock_02_3");
            asteroid_3.rotation = new Vector3(-Math.PI, -Math.PI/3, -Math.PI/2);
            asteroid_3.scalingDeterminant = 0.08;
            asteroid_3.renderingGroupId = 1;
            sun.excludedMeshes.push(asteroid_3);

            // Positioning Elements
            if (deviceType == "desktop"){
                moon.position = new Vector3(-2.4, 0.8, 8.0);
                asteroid.position = new Vector3(1.75, -0.85, 5.1);
                asteroid_1.position = new Vector3(-1.85, -0.85, 5.1);
                asteroid_2.position = new Vector3(2.85, -0.85, 6.1);
                asteroid_3.position = new Vector3(-3.55, -1.45, 9.1);
            } else {
                if (deviceType == "mobilePortrait"){
                    moon.position = new Vector3(-1.2, 3.5, 4.0);
                    asteroid.position = new Vector3(1.0, 2.5, 7.1);
                    asteroid_1.position = new Vector3(-1.1, 2.5, 7.1);
                    asteroid_2.position = new Vector3(2.0, 3.0, 7.1);
                    asteroid_3.position = new Vector3(-1.95, 3.0, 7.1);
                } else {
                    moon.position = new Vector3(-1.8, 0.8, 3.5);
                    asteroid.position = new Vector3(1.75, -0.85, 5.1);
                    asteroid_1.position = new Vector3(-1.85, -0.85, 5.1);
                    asteroid_2.position = new Vector3(2.85, -0.85, 6.1);
                    asteroid_3.position = new Vector3(-3.55, -1.45, 9.1);
                }
                if (this._engine.getRenderWidth(true) < 450) {
                    asteroid.position.y -= 0.75;
                    asteroid_1.position.y -= 0.75;
                    asteroid_2.position.y -= 0.75;
                    asteroid_3.position.y -=0.75;
                    asteroid.position.x += 0.75;
                    asteroid_1.position.x -= 0.75;
                    asteroid_2.position.x += 0.75;
                    asteroid_3.position.x -= 0.75;
                    //console.log("ZOOM");
                }        
            }

            Animation.CreateAndStartAnimation("fadein", fade, "visibility", 60, 180, 1, 0, 0);
            Animation.CreateAndStartAnimation("fadein", clouds, "visibility", 30, 180, 0, 1, 0);
            Animation.CreateAndStartAnimation("fadein", moon, "visibility", 60, 180, 0, 1, 0);
            Animation.CreateAndStartAnimation("fadein", halo, "visibility", 60, 120, 0, 1, 0);
            this._scene.meshes.forEach(element => {
                if (element.name.indexOf("Earth") !== -1){
                    Animation.CreateAndStartAnimation("fadein", element, "visibility", 80, 210, 0, 1, 0);
                }
            });

            setTimeout(()=>{
                let cnt = 0.01;
                this._scene.onBeforeRenderObservable.add(()=>{
                    if (this._scene.environmentIntensity < 3.5)
                        this._scene.environmentIntensity += cnt;
    
                    if (clouds.material.emissiveIntensity < 1.5)
                        clouds.material.emissiveIntensity += cnt/10;
    
                    if (halo.material.emissiveIntensity < 3.0)
                        halo.material.emissiveIntensity += cnt;

                    if (gl.intensity < 2)
                        gl.intensity += cnt/10;
                })        
            }, 1500);

            gl.customEmissiveColorSelector = function(mesh, subMesh, material, result) {
                if (mesh.name.indexOf("Earth") !== -1) {
                    //result.set(0.0, 0.06, 0.1, 2);
                    result.set(0.0, 0.12, 0.2, 4);
                } else if (mesh.name.indexOf("Moon") !== -1) {
                    //result.set(0.0, 0.04, 0.075, 2/90);
                    result.set(0.0, 0.08, 0.15, 2/45);
                } else if (mesh.name.indexOf("Rock") !== -1) {
                    result.set(0.0, 0.04, 0.075, 2/45);
                }
            }
        
            // PARTICLE STARFIELD
            var sphere = MeshBuilder.CreateSphere("sphere",{diameter: 10}, this._scene);

            var starMat = new StandardMaterial("stars", this._scene);
            var texStar = new Texture(this._baseUrl + "speckles_new_2.jpg", this._scene);
            texStar.uScale = 1;
            texStar.vScale = 1;
            starMat.diffuseTexture = texStar;
            starMat.emissiveTexture = texStar;
            starMat.emissiveIntensity = 100;
            starMat.environmentIntensity = 50;
            starMat.useEmissiveAsIllumination = true;
            sphere.material = starMat;

            var pcs= new PointsCloudSystem("pcs", 10, this._scene) 

            // STARS QUANTITY
            pcs.addSurfacePoints(sphere, 150000, PointColor.Color, );
            pcs.buildMeshAsync().then((mesh) => {
                // STARS SIZE            
                //mesh.material.pointSize = 2.5;
                //console.log(this._engine.getRenderWidth(true)/500);
                mesh.material.pointSize = this._engine.getRenderWidth(true)/1500;
                gl.addExcludedMesh(mesh);
                sphere.dispose();
            });

        });
        
        return this._scene;
    }

    renderScene() {
        // Start render loop.
        this._engine.runRenderLoop(() => {
            this._scene.render();
        });
    }
}