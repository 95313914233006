import React from 'react';

import site_logo_dark from '../assets/images/site_logo_dark.png';
import eye from '../assets/images/Icon-awesome-eye.svg';
import eyeSlash from '../assets/images/Icon-awesome-eye-slash.svg';
import { FaChevronLeft } from "react-icons/fa";

import { CONSTANTS } from '../constants';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { apiCall } from '../common/NetworkCalls';
import { API_ENDPOINTS } from '../constants/api-endpoints';
import { MESSAGES } from '../constants/messages';

const PasswordSchema = Yup.object().shape({
    new_password: Yup.string().required('New password is required').matches(CONSTANTS.REGEX.PASSWORD, 'Password is invalid'),
    confirm_password: Yup.string().required('Confirm password is required').matches(CONSTANTS.REGEX.PASSWORD, 'Password is invalid').test('passwords-match', 'Both Passwords must match', function (value) {
        return this.parent.new_password === value
    })
});

class CreatePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewPass: false,
            showConfirmPass: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (!(this.props.forgotData.info && this.props.forgotData.info.phone_number)) {
            this.props.history.push('/login');
        }
    }

    togglePassword(fieldToToggle) {
        if (fieldToToggle === 'showNewPass') {
            this.setState({ showNewPass: !this.state.showNewPass });
        } else {
            this.setState({ showConfirmPass: !this.state.showConfirmPass });
        }
    }

    handleCreate(values) {
        this.setState({ isLoading: true });
        return apiCall(API_ENDPOINTS.RESET, 'POST', { ...values, id: this.props.forgotData.info.user_id }).then((response) => {
            if (response.status.toString() === '200') {
                this.props.history.push('/login');
            } else {
                this.props.alert.show(response.message);
                this.setState({ isLoading: false });
            }
        }).catch((error) => {
            this.props.alert.show(MESSAGES.SOMETHING_WRONG);
            this.setState({ isLoading: false });
        })
    }

    render() {
        return (
            <div className="Login-page create-password-page">
                <div className="row no-gutters">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row no-gutters">
                            <div className="d-flex bank-arrow-position">
                                <Link to={''} className="back-link">
                                    <FaChevronLeft className="arrow" /><span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters d-flex flex-column card">
                            <div className="card-body">
                                <img src={site_logo_dark} alt="site logo" className="login-page-logo" />
                                <p className="new-password-label">Please create a new password.</p>
                                <Formik
                                    initialValues={{ new_password: '', confirm_password: '' }}
                                    validationSchema={PasswordSchema}
                                    onSubmit={values => {
                                        // same shape as initial values
                                        console.log(values);
                                        this.handleCreate(values);
                                    }}
                                >
                                    {({ handleSubmit, errors, touched, handleChange, handleBlur }) => (
                                        <form className="login-form" onSubmit={handleSubmit}>
                                            <div className="form-group field_cont">
                                                <label htmlFor="new_password" className="form-label">New Password</label>
                                                <input placeholder="Enter your password"
                                                    type={this.state.showNewPass ? 'text' : "password"}
                                                    onChange={handleChange} onBlur={handleBlur} name="new_password"
                                                    className={`form-control ${touched.new_password && errors.new_password ? 'invalid' : ''}`}
                                                />
                                                <img src={this.state.showNewPass ? eye : eyeSlash} alt="" className="eye-img" onClick={
                                                    () => {
                                                        this.togglePassword('showNewPass');
                                                    }
                                                } />
                                                {touched.new_password && errors.new_password ?
                                                    <span className="error_msg">{errors.new_password}</span>
                                                    : null
                                                }
                                            </div>
                                            <div className="form-group field_cont">
                                                <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                                <input placeholder="Enter your confirm password"
                                                    type={this.state.showConfirmPass ? 'text' : "password"}
                                                    onChange={handleChange} onBlur={handleBlur} name="confirm_password"
                                                    className={`form-control ${touched.confirm_password && errors.confirm_password ? 'invalid' : ''}`}
                                                />
                                                <img src={this.state.showConfirmPass ? eye : eyeSlash} alt="" className="eye-img" onClick={
                                                    () => {
                                                        this.togglePassword('showConfirmPass');
                                                    }
                                                } />
                                                {touched.confirm_password && errors.confirm_password ?
                                                    <span className="error_msg">{errors.confirm_password}</span>
                                                    : null
                                                }
                                            </div>
                                            <button type="submit" className="btn" disabled={this.state.isLoading}>
                                                {this.state.isLoading ?
                                                    <div className="spinner-grow spinner-grow-sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <span>CONFIRM NEW PASSWORD</span>
                                                }
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                                <div className="password-req-wrapper">
                                    <span className="req-header">Password Requirements</span>
                                    <div className="password-req-wrapper req-list">
                                        <div className="password-req-wrapper list-item">• Be a minimum of eight (8) characters in length.</div>
                                        <div className="password-req-wrapper list-item">• Contain at least one (1) character from three (3) of the following categories: Uppercase letter (A-Z) Lowercase letter (a-z) Digit (0-9) Special character #(~`! …</div>
                                        <div className="password-req-wrapper list-item">• Be memorized; if a password is written down it must be secure.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="Banner d-none d-md-block d-lg-block d-xl-block">
                            <div className="content-background">
                                <div className="banner-content">
                                    <h2 className="banner-heading">Suspendisse ut sapien id neque.</h2>
                                    <div className="blueDash"></div>
                                    <p className="banner-tagline">
                                        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    forgotData: state.forgotData
});

export default withRouter(connect(mapStateToProps, null)(withAlert()(CreatePasswordPage)));
