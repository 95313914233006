import { FORGOT_SUBMITTED, FORGOT_FAILED_TO_SUBMIT } from '../actions/forgot-password';

const INITIAL_STATE = {
    info: {},
    message: ''
};

const forgotReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORGOT_SUBMITTED:
            state = { ...state, info: action.payload };
            return state;
        case FORGOT_FAILED_TO_SUBMIT:
            state = { ...state, info: {}, message: action.payload };
            return state;
        default:
            return state;
    }
}

export default forgotReducer;