import React from 'react';
import Enterprise from '../../assets/images/Enterprise.svg';
import CiircleBg from '../../assets/images/ellipse-1-copy-9.png';
import ProblemSolving from '../../assets/images/Problem-Solving.svg';
import identify from '../../assets/images/identity.svg';
import Culture from '../../assets/images/Culture.svg';
import TestProject from '../../assets/images/Test-Project.svg';
import OngoingAI from '../../assets/images/On-goingAI.svg';
import Staff from '../../assets/images/staff.svg';
import Puzzle from '../../assets/images/puzzle-icon.png';
import WhyTab from '../../assets/images/howPage-whyTab.svg';
import HowTab from '../../assets/images/whyPage-whyTab.svg';
import whatTab from '../../assets/images/howPage-whatTab.svg';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SubNavigation from '../../components/Sub-navigation';
import { Link } from 'react-router-dom';

import { getFromStorage, removeFromStorage } from '../../common/service';
import { CONSTANTS } from '../../constants';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../../constants/api-endpoints';
import { apiCall } from '../../common/NetworkCalls';
import { withRouter } from 'react-router-dom';

class HowPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserLoggedIn: false,
            userInfo: ''
        }
    }

    componentDidMount() {
        var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        console.log(userData)
        if (userData) {
            userData = JSON.parse(userData);
            this.setState({ isUserLoggedIn: true, userInfo: userData });
        }
    }

    logOutUser() {
        this.setState({ isUserLoggedIn: false, userInfo: '' });
        removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
        var headers = PLAIN_HEADERS;
        headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
        return apiCall(API_ENDPOINTS.LOGOUT, 'GET', '',).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    render() {

        return (
            <div className="about-page how-page">
                <Header
                    isUserLoggedIn={this.state.isUserLoggedIn}
                    userInfo={this.state.userInfo}
                    logOutUser={() => {
                        this.logOutUser()
                    }}
                />
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active trusteddev-tab" id="trustedDev" role="tabpanel" aria-labelledby="trustedDev-tab">
                        <div className="how-page-banner">
                            <SubNavigation
                                isUserLoggedIn={this.state.isUserLoggedIn}
                                userInfo={this.state.userInfo}
                                logOutUser={() => {
                                    this.logOutUser()
                                }}
                            />
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12 nav-pill-position">
                                <div className="row justify-content-center no-gutters">
                                    <nav className="nav nav-pills nav-justified">
                                        <Link className="nav-link nav-pill" to={'why'}>
                                            <img src={WhyTab} alt="why-tab" className="header-tab" />
                                            <label className="whypage-tabLabel color_white">Why?</label>
                                        </Link>
                                        <Link className="nav-link nav-pill" to={'how'}>
                                            <img src={HowTab} alt="how-tab" className="header-tab active-tab" />
                                            <label className="whypage-tabLabel color_color6">How?</label>
                                        </Link>
                                        <Link className="nav-link nav-pill" to={'what'}>
                                            <img src={whatTab} alt="what-tab" className="header-tab" />
                                            <label className="whypage-tabLabel color_what_inactive">What?</label>
                                        </Link>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 wow">
                                <div className="card text-center card_border bg_transperant">
                                    <div className="card-header card_border bg_sky_blue">
                                        <div className="offset-xl-3 col-xl-9 offset-lg-3 col-lg-9 offset-md-3 col-md-9 col-sm-12">
                                            <h2 className="why-trustedDev">
                                                <span className="text-style">How</span> Trusted Dev Works?
                                        </h2>
                                        </div>
                                    </div>
                                    <div className="card-body card_body_color overflowX">
                                        <div className="row no-gutters">
                                            <div className="offset-xl-3 col-xl-9 offset-lg-3 col-lg-9 offset-md-3 col-md-9 col-sm-12">
                                                <div className="row no-gutters card-align">
                                                    <div className="col-6">
                                                        <div>
                                                            <div className="padding0">
                                                                <div>
                                                                    <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                    <img src={Enterprise} className="icon icon-Enterprise" alt="" />
                                                                </div>
                                                                {/* <img src={ identifyImg } className="icon_bg wow animate__animated animate__bounceIn" alt="" data-wow-delay=".6s" />  */}
                                                                <span className="card_lineLeft_1 card_line">
                                                                    <span className="digit digit_01">01</span>
                                                                    <span className="blue_dot blue_dot_step1"></span>
                                                                </span>
                                                                <div>
                                                                    <h3 className="How-TrustedDev-Works-Heading">
                                                                        Enterprise Registration
                                                                </h3>
                                                                    <p className="How-TrustedDev-Works-Content">
                                                                        Your enterprise software development hiring team registers an account on The Trusted Dev Portal.
                                                                </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                <img src={ProblemSolving} className="icon icon-ProblemSolving" alt="" />
                                                            </div>
                                                            {/* <img src={ problemSolvingImg } className="icon_bg wow animate__animated animate__bounceIn" data-wow-delay="1.2s" alt="" />   */}
                                                            <span className="card_lineLeft_3 card_line">
                                                                <span className="digit digit_03">03</span>
                                                                <span className="blue_dot blue_dot_step3"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="How-TrustedDev-Works-Heading">
                                                                    Problem Solving and Technical Assessment
                                                            </h3>
                                                                <p className="How-TrustedDev-Works-Content">
                                                                A quantifiable technical assessment is proctored to expertly curated IT talent ensuring technical and creative problem solving proficiency based on your preferred tech stack.
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                <img src={TestProject} className="icon icon-TestProject" alt="" />
                                                            </div>
                                                            {/* <img src={ testProjectImg } className="icon_bg wow animate__animated animate__bounceIn" data-wow-delay=".3s" alt="" />   */}
                                                            <span className="card_lineLeft_5 card_line">
                                                                <span className="digit digit_05">05</span>
                                                                <span className="blue_dot blue_dot_step5"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="How-TrustedDev-Works-Heading">
                                                                    TEST PROJECT
                                                            </h3>
                                                                <p className="How-TrustedDev-Works-Content">
                                                                    A test project is completed by the candidate and scored by our team to simulate a live project.
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgLtr" />
                                                                <img src={Staff} className="icon icon-Staff" alt="" />
                                                            </div>
                                                            {/* <img src={ brainImg } className="icon_bg wow animate__animated animate__bounceIn" data-wow-delay=".9s" alt="" />   */}
                                                            <span className="card_lineLeft_5 card_line">
                                                                <span className="digit digit_05">07</span>
                                                                <span className="blue_dot blue_dot_step5"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="text-center color_color6 capital_letter font18 font-family_lato-black padding40 staff-hire-label">Your staff IS <br /> READY TO HIRE</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="position-relative">
                                                            <div >
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgRtl" />
                                                                <img src={identify} className="icon icon-identity" alt="" />
                                                            </div>
                                                            {/* <img src={ identifyImg } className="icon_bg_right wow animate__animated animate__bounceIn" alt="" data-wow-delay=".9s" />   */}
                                                            <span className="card_lineRight_2 card_line">
                                                                <span className="digit digit_02">02</span>
                                                                <span className="blue_dot blue_dot_step2"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="How-TrustedDev-Works-Heading">
                                                                    Identify Talent
                                                            </h3>
                                                                <p className="How-TrustedDev-Works-Content">
                                                                We identify then vet top software development and IT talent from a technical, creative problem solving, communication and tailored culture fit perspective.
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative">
                                                            <div >
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgRtl circle-margin" />
                                                                <img src={Culture} className="icon icon-Culture" alt="" />
                                                            </div>
                                                            {/* <img src={ fitImg } className="icon_bg_right margin0 wow animate__animated animate__bounceIn" alt="" data-wow-delay="1.5s" />   */}
                                                            <span className="card_lineRight_4 card_line">
                                                                <span className="digit digit_04">04</span>
                                                                <span className="blue_dot blue_dot_step4"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="How-TrustedDev-Works-Heading">
                                                                    Culture Fit Assessment 
                                                            </h3>
                                                                <p className="How-TrustedDev-Works-Content">
                                                                Our team meets with the candidate and administers a culture fit assessment to ensure the alignment of their personality and work ethic with your internal company culture.
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative">
                                                            <div >
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgRtl circle-margin" />
                                                                <img src={OngoingAI} className="icon icon-OngoingAI" alt="" />
                                                            </div>
                                                            {/* <img src={ brainImg } className="icon_bg_right margin0 wow animate__animated animate__bounceIn" alt=""  data-wow-delay=".6s"/>   */}
                                                            <span className="card_lineRight_4 card_line">
                                                                <span className="digit digit_04">06</span>
                                                                <span className="blue_dot blue_dot_step4"></span>
                                                            </span>
                                                            <div>
                                                                <h3 className="How-TrustedDev-Works-Heading">
                                                                    On-going AI and Human Optimization
                                                            </h3>
                                                                <p className="How-TrustedDev-Works-Content">
                                                                    We utilize AI, machine learning and passionate humans from our team to optimize your technical hiring life cycle resulting in unlocked efficiencies.
                                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative">
                                                            <div >
                                                                <img src={CiircleBg} alt="bg" className="icon-circleBgRtl circle-margin" />
                                                                <img src={Puzzle} className="icon icon-puzzle" alt="" />
                                                            </div>
                                                            {/* <img src={ brainImg } className="icon_bg_right margin0 wow animate__animated animate__bounceIn" alt=""  data-wow-delay="1.2s"/>   */}
                                                            <span className="card_lineLast card_line">
                                                            </span>
                                                            <span className="circle_blue_large circle_blue">
                                                                <span className="circle_blue_medium circle_blue">
                                                                    <span className="blue_dot last_dot">
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="dotted_line"></div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h2 className="How-TrustedDev-Works-Tagline remove-text-transform">
                                                            The <span className="text-style">Pre-vetted Software Engineer</span> Joins Your Internal Team!
                                                    </h2>
                                                        <Link
                                                            className="nav-link hire_btn btn"
                                                            to={{
                                                                pathname: "/freelance-info",
                                                                state: { id: new Date().valueOf() }
                                                            }}>Hire Top 1% Developers</Link>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-page-why-footer-banner">
                            {/* <h3 className="Proud-Member-of-the-Following-Organizations p-4 wow animate__fadeIn animate__animated" data-wow-delay=".1s">Proud <span className="color_color1">Member</span> of the Following Organizations:</h3>
                            <div className="row no-gutters justify-content-start wow animate__slideInLeft animate__faster animate__animated" data-wow-delay=".2s">
                                <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                                    <div className="row justify-content-end">
                                        <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                                            <img src={shape_19} className="image image_PML_img" alt="" />
                                            <img src={shape_20} className="image image_w100" alt="" />
                                            <img src={shape_21} className="image image_w150" alt="" />
                                            <img src={shape_22} className="image image_w160" alt="" />
                                            <img src={shape_23} className="image image_w100" alt="" />
                                            <img src={shape_24} className="image image_w100" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_19} className="image image_w100" alt="" />
                                        <img src={shape_20} className="image image_w100" alt="" />
                                        <img src={shape_21} className="image image_w150" alt="" />
                                    </div>
                                    <div className="d-flex justify-content-around align-items-end">
                                        <img src={shape_22} className="image image_w160" alt="" />
                                        <img src={shape_23} className="image image_w100" alt="" />
                                        <img src={shape_24} className="image image_w100" alt="" />
                                    </div>
                                </div>
                            </div> */}
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HowPage);