import React from 'react';
import Routes from './routes';

import "bootstrap/scss/bootstrap.scss";

import { transitions, positions, Provider as AlertProvider } from 'react-alert';

// optional configuration
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style} className="toast_cont">
    <div className="message">{message}</div>
    <button className="btn text-danger" onClick={close}>Hide</button>
  </div>
)

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <div className="App">
        <Routes />
      </div>
    </AlertProvider>
  );
}

export default App;
