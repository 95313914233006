import React from 'react';

import site_logo_dark from '../assets/images/site_logo_dark.png';
import { FaChevronLeft } from "react-icons/fa";

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser } from '../actions/login';

import { withAlert } from 'react-alert';
import { resetUserForm } from '../actions/basic-info';

const LoginSchema = Yup.object().shape({
    email_id: Yup.string().required('Email Address is required').email("Please enter valid email address"),
    password: Yup.string().required('Password is required')
});

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    handleLogin(formData) {
        this.setState({ isLoading: true });
        this.props.loginUser(formData).then((validUser) => {
            this.setState({ isLoading: false });
            if(validUser){
                this.props.history.push('verify/login');
            }else{
                this.props.alert.show(this.props.userData.message);
            }
        });
    }

    render() {
        return (
            <div className="Login-page">
                <div className="row no-gutters">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row no-gutters">
                            <div className="d-flex bank-arrow-position">
                                <Link to="/" className="back-link">
                                    <FaChevronLeft className="arrow" /><span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters d-flex flex-column card">
                            <div className="card-body">
                                <img src={site_logo_dark} alt="site logo" className="login-page-logo" />
                                <h5 className="welcome-back-label">Welcome Back!</h5>
                                <p className="please-login-line">Please login to proceed.</p>
                                <Formik
                                    initialValues={{ email_id: "", password: "" }}
                                    validationSchema={LoginSchema}
                                    onSubmit={values => {
                                        // same shape as initial values
                                        console.log(values);
                                        this.handleLogin(values);
                                    }}
                                >
                                    {({ handleSubmit, errors, touched, handleChange, handleBlur }) => (
                                        <form className="login-form" onSubmit={handleSubmit}>
                                            <div className="form-group field_cont">
                                                <label htmlFor="email_id" className="form-label">Email</label>
                                                <input type="text" name="email_id"
                                                    id="email_id" autoComplete="off" placeholder="Enter your email"
                                                    onChange={handleChange} onBlur={handleBlur}
                                                    className={`form-control ${touched.email_id && errors.email_id ? 'invalid' : ''}`}
                                                />
                                                {touched.email_id && errors.email_id ?
                                                    <span className="error_msg">{errors.email_id}</span>
                                                    : null
                                                }
                                            </div>
                                            <div className="form-group field_cont mb-0">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input type="password" name="password"
                                                    id="password" placeholder="Enter your password"
                                                    onChange={handleChange} onBlur={handleBlur}
                                                    className={`form-control ${touched.password && errors.password ? 'invalid' : ''}`}
                                                />
                                                {touched.password && errors.password ?
                                                    <span className="error_msg">{errors.password}</span>
                                                    : null
                                                }
                                            </div>
                                            <Link className="forgot-password" to="forgot-password"> Forgot password? </Link>
                                            <button type="submit" className="btn" disabled={this.state.isLoading}>
                                                {this.state.isLoading ?
                                                    <div className="spinner-grow spinner-grow-sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> 
                                                    :
                                                    <span>SIGN IN TO YOUR ACCOUNT</span>
                                                }
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                                <div className="sign-up-link">
                                    <span className="d-flex justify-content-center align-items-baseline">No account?&nbsp;<button className="sign-up btn btn-link p-0" onClick={() => {
                                        this.props.resetUserForm();
                                        this.props.history.push("/basic-info");
                                    }}>Sign up.</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="Banner d-none d-md-block d-lg-block d-xl-block">
                            <div className="content-background">
                                {/* <div className="banner-content">
                                    <h2 className="banner-heading">Suspendisse ut sapien id neque.</h2>
                                    <div className="blueDash"></div>
                                    <p className="banner-tagline">
                                        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

const mapStateToProps = (state) => ({
    userData: state.loginData
});

const mapActionsToProps = {
    loginUser,
    resetUserForm
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(LoginPage)));
