import { apiCall } from "../common/NetworkCalls";
import { API_ENDPOINTS } from "../constants/api-endpoints";
import { MESSAGES } from "../constants/messages";

export const FORGOT_SUBMITTED = 'forgot_submitted';
export const FORGOT_FAILED_TO_SUBMIT = 'forgot_failed_to_submit';

function forgotSubmitted(info){
    return {
        type: FORGOT_SUBMITTED, 
        payload: info
    }
}

function forgotFailedToSubmit(message){
    return {
        type: FORGOT_FAILED_TO_SUBMIT, 
        payload: message
    }
}

export function handleForget(reqData){
    return (dispatch) => {
        return apiCall(API_ENDPOINTS.FORGOT, 'POST', reqData).then((response) => {
            if(response.status.toString() === '200'){
                dispatch(forgotSubmitted(response.data));
                return true;
            }else{
                dispatch(forgotFailedToSubmit(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(forgotFailedToSubmit(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}