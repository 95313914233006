import { apiCall } from "../common/NetworkCalls";
import { API_ENDPOINTS } from "../constants/api-endpoints";
import { MESSAGES } from "../constants/messages";

export const FILLED_SURVEY_PART_ONE = 'filled_survey_part_one';
export const FILLED_SURVEY_PART_TWO = 'filled_survey_part_two';
export const FAILED_TO_SUBMIT_SURVEY_DATA = 'failed_to_submit_survey_data';

export function filledSurveyPartOne(data){
    return {
        type: FILLED_SURVEY_PART_ONE, 
        payload: data
    }
}

export function filledSurveyPartTwo(data){
    return {
        type: FILLED_SURVEY_PART_TWO, 
        payload: data
    }
}

function failedToSubmitSurveyData(message){
    return {
        type: FAILED_TO_SUBMIT_SURVEY_DATA, 
        payload: message
    }
}

export function submitSurvey(data, headers, partData){
    return (dispatch) => {
        return apiCall(`${API_ENDPOINTS.SURVEY}`, 'POST', data, headers).then((response) => {
            if(response.status.toString() === '200'){
                if(partData.type === '1'){
                    dispatch(filledSurveyPartOne(partData.data));
                }else{
                    dispatch(filledSurveyPartTwo(partData.data));
                }
                return true;
            }else{
                dispatch(failedToSubmitSurveyData(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(failedToSubmitSurveyData(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}