import React, { useEffect } from 'react';

import { WebGL } from './graphic-loader';

const BgAnimation = () => {
    useEffect(() => {
        // Instantiate engine.
        let webgl = new WebGL('render-canvas');

        // Create scene.
        webgl.createScene();

        // Start render loop.
        webgl.renderScene();
    }, []);

    return (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', borderRadius: '0px 0px 110px 0px', overflow: 'hidden' }}>
            <canvas id='render-canvas' style={{ width: '100%', height: '100%' }} />
        </div>
        
    );
}

export default BgAnimation;