import React from 'react';
import siteLogo from '../../assets/images/site_logo_light.svg';
import backArrow from '../../assets/images/back-arrow.svg';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { filledSurveyPartOne, filledSurveyPartTwo, submitSurvey } from '../../actions/survey';
import { CONSTANTS } from '../../constants';
import { getFromStorage, removeFromStorage } from '../../common/service';
import { AUTH_HEADER_KEY, PLAIN_HEADERS } from '../../constants/api-endpoints';
import { MESSAGES } from '../../constants/messages';

class Survey2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            surveyInfo: [...CONSTANTS.QUESTIONS.PART_TWO]
        }
    }

    componentDidMount() {
        if (this.props.surveyData.partTwo && this.props.surveyData.partTwo.length > 0) {
            this.setState({ surveyInfo: this.props.surveyData.partTwo });
        }else{
            var temp = CONSTANTS.QUESTIONS.PART_TWO;
            temp = temp.map((question) => {
                question.options = question.options.map((item) => {
                    item.isChecked = false;
                    return item;
                });
                return question;
            });
            this.setState({ surveyInfo: temp });
        }
    }

    handleSubmit() {
        var isOtherFilled = true;
        var surveyDetails = this.state.surveyInfo.map((question) => {
            var answer = question.options.filter((option) => option.isChecked);
            answer = answer.map((item) => {
                if(item.value === "Other"){
                    if(item.extra){
                        return `${item.value}|${item.extra}`;
                    }else{
                        isOtherFilled = false;
                        return `${item.value}`;    
                    }
                }else{
                    return `${item.value}`;
                }
            }).join(',');
            return { que_text: question.que_text, que_index: question.que_index, answer };
        });
        var temp = surveyDetails.filter((item) => !item.answer);
        console.log(temp, surveyDetails);
        if (temp.length > 0) {
            this.props.alert.show(MESSAGES.UNFILLED_SURVEY);
        } else {
            var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
            console.log(userData)
            if(!isOtherFilled){
                this.props.alert.show(MESSAGES.UNFILLED_SURVEY);
                return;
            }

            if (userData) {
                this.setState({ isLoading: true });

                userData = JSON.parse(userData);
                var headers = PLAIN_HEADERS;
                headers[AUTH_HEADER_KEY] = `Bearer ${userData.authToken}`;
                var data = { user_id: userData.user_id, survey_data: surveyDetails };

                this.props.submitSurvey(data, headers, { type: '2', data: this.state.surveyInfo }).then((filled) => {
                    if (filled) {
                        if(this.props.basicData.info){
                            removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
                            this.props.filledSurveyPartOne([]);
                            this.props.filledSurveyPartTwo([]);
                            this.props.history.push('/congrats'); 
                        }else{
                            this.props.history.push('/');
                        }
                    } else {
                        this.props.alert.show(this.props.surveyData.message);
                        this.setState({ isLoading: false });
                    }
                });
            }else{
                this.props.history.push('/login');
            }
        }
    }

    render() {
        return (
            <div className="Survey-Page">
                <div className="row no-gutters">
                    <div className="col-md-6 dark-bckground d-none d-md-block d-lg-block d-xl-block">
                        <img src={siteLogo} alt="" className="site-logo" />
                        <div className="blue-longdash"></div>
                        <h3 className="trustedDev-register-label">Requirements Personalization Survey</h3>
                        <div className="header-Banner"></div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="card survey-card">
                            <nav className="navbar navbar-dark">
                                <div className="d-flex progress-bar-wrapper">
                                    <a href="#/" className="header-text">
                                        <img src={backArrow} alt="" className="back-arrow" /> <span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                    </a>
                                    <div className="d-flex">
                                        <div className="">
                                            <label className="progress-bar-label active-label">Basic info</label>
                                            <div className="progress progress-one">
                                                <div className="progress-bar progress-bar-active" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label active-label">Survey 1/2</label>
                                            <div className="progress progress-two">
                                                <div className="progress-bar progress-bar-active" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label inactive-label">Survey 2/2</label>
                                            <div className="progress progress-three">
                                                <div className="progress-bar progress-bar-inactive" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="progress-bar-label last-progress-bar"></label>
                                            <div className="progress progress-four">
                                                <div className="progress-bar progress-bar-inactive" role="progressbar" style={{ width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <div className="card-body-wrapper">
                                <div className="card-body">
                                    <form className="questionnaire-form" onSubmit={(event) => {
                                        event.preventDefault();
                                        this.handleSubmit();
                                        return false;
                                    }}>
                                        <div className="form-field-wrapper">
                                            <h4 className="basic-form-headline">
                                                Please help us model your hiring profile by filling out this short survey.
                                            </h4>
                                            {this.state.surveyInfo.map((question, queIndex) => {
                                                return (
                                                    question.type === 'checkbox' ?
                                                        <div className={`form-group ${(queIndex === this.state.surveyInfo.length - 1) ? 'pb-5' : ''}`}
                                                            key={`question${question.que_index + 1}`}
                                                        >
                                                            <label htmlFor={`question${question.que_index + 1}`} className="form-label">{`${question.que_index + 1}. ${question.que_text}`}</label>
                                                            {question.options.map((option, index) => {
                                                                return (
                                                                    <div className="position-relative margin30"
                                                                        key={`question${question.que_index + 1}-option${index + 1}`}
                                                                    >
                                                                        <div className="checkbox-container circular-container">
                                                                            <label className="checkbox-label">
                                                                                <input type="checkbox" id={`que${question.que_index + 1}-opt${index + 1}`}
                                                                                    checked={option.isChecked}
                                                                                    onChange={(event) => {
                                                                                        var temp = this.state.surveyInfo;
                                                                                        temp[queIndex].options[index].isChecked = event.target.checked;
                                                                                        this.setState({ surveyInfo: temp });
                                                                                    }}
                                                                                /><span className="checkbox-custom circular"></span>
                                                                            </label>
                                                                            <label className="checkbox-text" htmlFor={`que${question.que_index + 1}-opt${index + 1}`}>{option.value}</label>
                                                                        </div>
                                                                        {option.value === 'Other' && option.isChecked ?
                                                                            <input type="text" name="checkbox-textfield" autoComplete="off" placeholder="Please enter your answer"
                                                                                className="optOther-textfield form-control" value={option.extra} onChange={(event) => {
                                                                                    var temp = this.state.surveyInfo;
                                                                                    temp[queIndex].options[index].extra = event.target.value;
                                                                                    this.setState({ surveyInfo: temp });
                                                                                }}
                                                                            />
                                                                            : null}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <div className={`form-group ${(queIndex === this.state.surveyInfo.length - 1) ? 'pb-5' : ''}`}
                                                            key={`question${question.que_index + 1}`}
                                                        >
                                                            <label htmlFor={`question${question.que_index + 1}`} className="form-label">{`${question.que_index + 1}. ${question.que_text}`}</label>
                                                            {question.options.map((option, index) => {
                                                                return (
                                                                    <div className="position-relative margin10 d-flex align-items-center flex-flow"
                                                                        key={`question${question.que_index + 1}-option${index + 1}`}
                                                                    >
                                                                        <div className="w-100">
                                                                            <input id={`question${question.que_index + 1}-option${index + 1}`}
                                                                                type="radio" name={`question${question.que_index + 1}`} value=""
                                                                                checked={option.isChecked}
                                                                                onChange={(event) => {
                                                                                    var temp = this.state.surveyInfo;
                                                                                    temp[queIndex].options = temp[queIndex].options.map((item) => ({ ...item, isChecked: false }));
                                                                                    temp[queIndex].options[index].isChecked = event.target.checked;
                                                                                    this.setState({ surveyInfo: temp });
                                                                                }}
                                                                            />
                                                                            <label>
                                                                                <span>
                                                                                    <span></span>
                                                                                </span>
                                                                                <label htmlFor={`question${question.que_index + 1}-option${index + 1}`} className="radiobtnbox-text">{option.value}</label>
                                                                            </label>
                                                                        </div>
                                                                        {option.value === 'Other' && option.isChecked ?
                                                                            <input type="text" name="radiobtn-textfield" autoComplete="off" placeholder="Please enter your answer" className="optOther-textfield form-control" value={option.extra} onChange={(event) => {
                                                                                var temp = this.state.surveyInfo;
                                                                                temp[queIndex].options[index].extra = event.target.value;
                                                                                this.setState({ surveyInfo: temp });
                                                                            }}
                                                                            />
                                                                            : null}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                )
                                            })}
                                        </div>
                                        <div className="card-footer">
                                            <button type="button" className="btn continue-btn gray-btn" onClick={() => {
                                                this.props.history.push('/questionnaire-one');
                                            }}>PREVIOUS STEP</button>
                                            <button type="submit" className="btn continue-btn" disabled={this.state.isLoading}>
                                                {this.state.isLoading ?
                                                    <div className="spinner-grow spinner-grow-sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <span>CONTINUE {'>'}</span>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    surveyData: state.surveyData,
    basicData: state.basicInfoData
});

const mapActionsToProps = {
    submitSurvey,
    filledSurveyPartOne,
    filledSurveyPartTwo
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(Survey2)));
