import React from 'react';
import siteLogo from '../assets/images/site_logo_light.svg';
// import twitterIcon from '../assets/images/twitter-icon.svg';
// import facebookIcon from '../assets/images/facebook-icon.svg';
// import linkedInIcon from '../assets/images/linkedIn-icon.svg';
import ActiveTab from '../assets/images/TrustedDev-activeTab.svg';
import mobiTrustedDevActive from '../assets/images/mobi-trustedDevActive.svg';
import mobiTrustedDevInactiveTab from '../assets/images/mobi-trustedDev-inactiveTab.svg';
import InactiveTab from '../assets/images/TrustedDev-inactiveTab.svg';
import { Link } from 'react-router-dom';

class Header extends React.Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-md navbar-dark bg_blue_nav top_navbar">
                    <div className="container top_nav">
                        <Link className="navbar-brand d-none d-md-block d-lg-block d-xl-block top-nav-logo" to={'/'}>
                            <img src={siteLogo} alt="" className="site-logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse">
                            <ul className="nav nav-tabs mr-auto tabs-section" id="myTab" role="tablist">
                                <li className="active-tab-listitem">
                                    <Link to={'/'}  > 
                                        <div className="d-none d-md-block d-lg-block d-xl-block">
                                            <img src={ActiveTab} alt="active-tab" className="activeTrustedDevTab" />
                                            <label className="TabLabel TabLabelTrustedDevActive-label">TRUSTED DEV PORTAL</label> 
                                        </div>
                                        <div className="d-md-none d-lg-none d-xl-none">
                                            <img src={mobiTrustedDevActive} alt="active-tab" className="activeTrustedDevTab" />
                                            <label className="TabLabel TabLabelTrustedDevActive-label">TRUSTED DEV<br /> PORTAL</label> 
                                        </div>
                                    </Link>
                                </li>
                                <li className="inactive-tab-listitem">
                                    <Link to={'cto'}  >
                                        <div className="d-none d-md-block d-lg-block d-xl-block">
                                            <img src={InactiveTab} alt="inactive-tab" className="InactiveTrustedDevTab" />
                                            <label className="TabLabel TabLabelTrustedDevInactive-label">CTO AS A SERVICE PORTAL</label>
                                        </div>
                                        <div className="d-md-none d-lg-none d-xl-none">
                                            <img src={mobiTrustedDevInactiveTab} alt="inactive-tab" className="InactiveTrustedDevTab" />
                                            <label className="TabLabel TabLabelTrustedDevInactive-label">CTO AS A SERVICE PORTAL</label>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            <div className="d-none d-md-block d-lg-block d-xl-block">
                                <ul className="navbar-nav">
                                    {/* <div className="d-flex margin-right">
                                        <li className="nav-item padding8">
                                            <a className="nav-link p-0" href="/#"> 
                                                <img src={ twitterIcon } alt="twitter" className="social-twitter-icon" />
                                            </a>
                                        </li>
                                        <li className="nav-item padding8">
                                            <a className="nav-link p-0" href="/#"> 
                                                <img src={ facebookIcon } alt="facebook" className="social-facebook-icon" />
                                            </a>
                                        </li> 
                                        <li className="nav-item padding8">
                                            <a className="nav-link p-0" href="/#"> 
                                                <img src={ linkedInIcon } alt="linkedIn" className="social-linkedIn-icon" />
                                            </a>
                                        </li> 
                                    </div>
                                    <span className="color_color4 font12">|</span> */}
                                    <div className="d-flex margin-left login-link-font">
                                        <li className="nav-item">
                                            {this.props.isUserLoggedIn ?
                                                <span className="d-flex align-items-center text-light font14 login-link-font">
                                                    <span className="">{this.props.userInfo && `${this.props.userInfo.first_name} ${this.props.userInfo.last_name}`}&nbsp;</span>
                                                    <button className="btn btn-link font-weight600 text_underline color_color1" onClick={() => {
                                                        this.props.logOutUser();
                                                    }}> Log out</button>
                                                </span>
                                                :
                                                <span className="d-flex text-light font14 login-link-font">
                                                    <span className="">Already a Member? &nbsp;</span>
                                                    <Link to={'login'} className="font-weight600 text_underline color_color1"> Log in</Link>
                                                </span>
                                            }
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}


export default Header;