import { USER_LOGGED_IN, USER_FAILED_TO_LOGIN } from '../actions/login';

const INITIAL_STATE = {
    info: {},
    message: ''
};

const loginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            state = { ...state, info: action.payload };
            return state;
        case USER_FAILED_TO_LOGIN:
            state = { ...state, info: {}, message: action.payload };
            return state;
        default:
            return state;
    }
}

export default loginReducer;