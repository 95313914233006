import React, { useState, useEffect } from 'react';
import siteLogo from '../../assets/images/site_logo_light.svg';
import backArrow from '../../assets/images/back-arrow.svg';

import { Widget } from '@typeform/embed-react'
import { Link, withRouter } from 'react-router-dom';

import { withAlert } from 'react-alert';

const BasicSurveyPage = ({ history, location }) => {
    const [showFirstClientSurvey, setShowFirstClientSurvey] = useState(location.state?.email);
    const [showSecondClientSurvey, setShowSecondClientSurvey] = useState(false);

    const [removeEmailSurvey, setRemoveEmailSurvey] = useState(false);
    const [removeHelperText, setRemoveHelperText] = useState(true);

    const [showFirstFreelanceSurvey, setShowFirstFreelanceSurvey] = useState(true);
    const [showSecondFreelanceSurvey, setShowSecondFreelanceSurvey] = useState(false);
    const [showThirdFreelanceSurvey, setShowThirdFreelanceSurvey] = useState(false);


    useEffect(() => {
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "Some task is in progress. Are you sure, you want to close?";
        }

        return () => {
            window.onbeforeunload = null;
            setShowFirstFreelanceSurvey(true);
            setShowSecondFreelanceSurvey(false);
            setShowThirdFreelanceSurvey(false);
        }

    }, [])


    const goBack = () => {
        history.push('/');
    }

    return (
        <div className="BasicIngo-Page">
            <div className="row no-gutters">
                <div className="col-md-6 dark-bckground d-none d-md-block d-lg-block d-xl-block">
                    <img src={siteLogo} alt="" className="site-logo" />
                    <div className="blue-longdash"></div>
                    <h3 className="trustedDev-register-label">TrustedDev Account Registration</h3>
                    <div className="Banner"></div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                    <div className="card basic-info-card">
                        <nav className="navbar navbar-dark">
                            <button className="header-text btn btn-link p-0" onClick={() => {
                                goBack();
                            }}>
                                <img src={backArrow} alt="" className="back-arrow" /> <span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                            </button>
                            <span className="header-text d-none d-md-block d-lg-block d-xl-block">I already have an account. <Link to={"/login"} className="sign-in">Sign in.</Link></span>
                        </nav>
                        <div className="card-body-wrapper survey-wrapper">
                            <div className="card-body survey-body">

                                {
                                    location.state?.id && <>
                                    {!removeHelperText && <div className="helper-text">
                                            <div className="helper-inner">
                                            <div className="helper-title">Hiring Manager Survey</div>
                                            <div className="helper-list-item">Please complete our form below so that we can connect you with our team.</div>
                                            <div className="helper-list-item">If you have any questions please email <a href="mailto:hello@trusteddev.ai" className="helper-link" target="_blank"
                                                rel="noreferrer"
                                            >hello@trusteddev.ai</a></div>
                                            </div>
                                        </div>}
                                        {
                                            <>

                                                {!removeEmailSurvey &&

                                                    <Widget id="QvApziHl" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                        id: location.state?.id
                                                    }}
                                                    onReady={() => {
                                                        setRemoveHelperText(false)
                                                    }}
                                                        onSubmit={() => {
                                                            setTimeout(() => {
                                                                setRemoveEmailSurvey(true)
                                                                setShowFirstClientSurvey(true)
                                                            }, 6000)
                                                        }}
                                                    />}
                                            </>

                                        }
                                        {
                                            showFirstClientSurvey && <Widget id="L5cfDDuQ" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                id: location.state?.id
                                            }}
                                                onSubmit={() => {
                                                    setTimeout(() => {
                                                        setShowSecondClientSurvey(true)
                                                        setShowFirstClientSurvey(false)
                                                    }, 6000)
                                                }}
                                            />
                                        }
                                        {
                                            showSecondClientSurvey &&
                                            <Widget id="tMjylMz0" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                id: location.state?.id
                                            }}
                                            onReady={() => {
                                                setRemoveHelperText(true)
                                            }}
                                                onSubmit={() => {
                                                    setTimeout(goBack, 5000)
                                                }}
                                            />
                                        }
                                    </>
                                }
                                {
                                    location.state?.email && <>
                                    {!removeHelperText && <div className="helper-text">
                                            <div className="helper-inner">
                                            <div className="helper-title">Hiring Manager Survey</div>
                                            <div className="helper-list-item">Please complete our form below so that we can connect you with our team.</div>
                                            <div className="helper-list-item">If you have any questions please email <a href="mailto:hello@trusteddev.ai" className="helper-link" target="_blank"
                                                rel="noreferrer"
                                            >hello@trusteddev.ai</a></div>
                                            </div>
                                        </div>}
                                        {
                                            showFirstClientSurvey && <Widget id="L5cfDDuQ" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                email: location.state?.email
                                            }}
                                                onReady={() => {
                                                    setRemoveHelperText(false)
                                                }}
                                                onSubmit={() => {
                                                    setTimeout(() => {
                                                        setShowSecondClientSurvey(true)
                                                        setShowFirstClientSurvey(false)
                                                    }, 6000)
                                                }}
                                            />
                                        }
                                        {
                                            showSecondClientSurvey &&
                                            <Widget id="tMjylMz0" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                email: location.state?.email
                                            }}

                                            onReady={() => {
                                                setRemoveHelperText(true)
                                            }}
                                                onSubmit={() => {
                                                    setTimeout(goBack, 5000)
                                                }}
                                            />
                                        }
                                    </>
                                }
                                {
                                    location.state?.date && <>
                                        {!removeHelperText && <div className="helper-text">
                                            <div className="helper-inner">
                                            <div className="helper-title">Freelancer Application</div>
                                            <div className="helper-list-item">Please complete our form below so that we can connect you with our team.</div>
                                            <div className="helper-list-item">If you have any questions please email <a href="mailto:recruitment@trusteddev.ai" className="helper-link" target="_blank"
                                                rel="noreferrer"
                                            >recruitment@trusteddev.ai</a></div>
                                            </div>
                                        </div>}
                                        {showFirstFreelanceSurvey &&
                                            <Widget id="rmo4VicC" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                id: location.state?.date
                                            }}
                                                onReady={() => {
                                                    setRemoveHelperText(false)
                                                }}
                                                onSubmit={(event) => {                                                    
                                                    setTimeout(() => {
                                                        setShowFirstFreelanceSurvey(false)
                                                        setShowSecondFreelanceSurvey(true)
                                                    }, 4000)
                                                }}
                                            />}
                                        {showSecondFreelanceSurvey &&
                                            <Widget id="uodVjcsB" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                id: location.state?.date
                                            }}
                                                onSubmit={() => {
                                                    setTimeout(() => {
                                                        setShowSecondFreelanceSurvey(false)
                                                        setShowThirdFreelanceSurvey(true)
                                                    }, 4000)
                                                }}
                                            />}
                                        {showThirdFreelanceSurvey &&
                                            <Widget id="r41JKurx" style={{ width: '100%', height: "100%" }} className="my-form" hidden={{
                                                id: location.state?.date
                                            }}
                                            onReady={() => {
                                                setRemoveHelperText(true)
                                            }}
                                                onSubmit={() => {
                                                    setTimeout(goBack, 5000)
                                                }}
                                            />}

                                    </>
                                }



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default withRouter((withAlert()(BasicSurveyPage)));
