import { 
    USER_REGISTERED, USER_REGISTRATION_FAILED, LOGGED_USER_DETAIL, UPDATED_USER_DETAIL, RESET_USER_FORM
} from "../actions/basic-info";

const INITIAL_DATA = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    company_url: "",
    job_title: "",
    terms: false,
    password: '',
    confirm_password: ''
}

const INITIAL_STATE = {
    info: INITIAL_DATA,
    message: ''
};

const basicInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_REGISTERED:
            state = { ...state, info: action.payload };
            return state;
        case USER_REGISTRATION_FAILED:
            state = { ...state, message: action.payload };
            return state;
        case LOGGED_USER_DETAIL:
            state = { ...state, info: {...state.info, ...action.payload} };
            return state;
        case UPDATED_USER_DETAIL:
            state = { ...state, info: {...state.info, ...action.payload} };
            return state;
        case RESET_USER_FORM:
            state = { ...state, info: {...state.info, ...INITIAL_DATA} };;
            return state;
        default:
            return state;
    }
}

export default basicInfoReducer;