import React from "react";
import shape_19 from "../assets/images/shape-19.png";
import shape_20 from "../assets/images/shape-20.png";
import shape_21 from "../assets/images/shape-21.png";
import shape_22 from "../assets/images/shape-22.png";
import shape_23 from "../assets/images/shape-23.png";
import shape_24 from "../assets/images/shape-24.png";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SubNavigation from "../components/Sub-navigation";

import { getFromStorage, removeFromStorage } from "../common/service";
import { CONSTANTS } from "../constants";
import {
  API_ENDPOINTS,
  AUTH_HEADER_KEY,
  PLAIN_HEADERS,
} from "../constants/api-endpoints";
import { apiCall } from "../common/NetworkCalls";
import { withRouter } from "react-router-dom";

class PrivacyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: false,
      userInfo: "",
    };
  }

  componentDidMount() {
    var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
    console.log(userData);
    if (userData) {
      userData = JSON.parse(userData);
      this.setState({ isUserLoggedIn: true, userInfo: userData });
    }
  }

  logOutUser() {
    this.setState({ isUserLoggedIn: false, userInfo: "" });
    removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
    var headers = PLAIN_HEADERS;
    headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
    return apiCall(API_ENDPOINTS.LOGOUT, "GET", "")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="trusteddev-tab AI-page privacy-page">
        <Header
          isUserLoggedIn={this.state.isUserLoggedIn}
          userInfo={this.state.userInfo}
          logOutUser={() => {
            this.logOutUser();
          }}
        />
        <div className="tab-content privacy-content" id="myTabContent">
          <div
            className="tab-pane fade show active trusteddev-tab"
            id="trustedDev"
            role="tabpanel"
            aria-labelledby="trustedDev-tab"
          >
            {/* <div className="AI-page-BackgroundColor"> */}
            <div className="AI-page-banner privacy-banner">
              <SubNavigation
                isUserLoggedIn={this.state.isUserLoggedIn}
                userInfo={this.state.userInfo}
                logOutUser={() => {
                  this.logOutUser();
                }}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div className="card text-center card_border card-margin">
              <div className="card-header card_border bg_sky_blue">
                <div className="offset-xl-3 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-8 col-sm-12 p-0">
                  <h2 className="AI-trustedDev">
                    PRIVACY POLICY
                    <p className="text-style">Trusted Dev</p>
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <div className="row no-gutters">
                  <div className="offset-xl-3 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-8 col-sm-12">
                    <div className="row no-gutters">
                      <div className="col-md-12 privacy-policy">
                        <p className="AI-paragraph">Version Date: 12/29/2021</p>
                        <p className="AI-paragraph">
                          <span className="text-style ">GENERAL</span>
                        </p>
                        <p className="AI-paragraph">
                          Trusted Dev, LLC (“Company” or “we” or “us” or “our”)
                          respects the privacy of its users (“user” or “you”)
                          that use our website located at www.trusteddev.ai,
                          including other media forms, media channels, mobile
                          website or mobile application related or connected
                          thereto (collectively, the “Website”). The following
                          Company privacy policy (“Privacy Policy”) is designed
                          to inform you, as a user of the Website, about the
                          types of information that Company may gather about or
                          collect from you in connection with your use of the
                          Website. It also is intended to explain the conditions
                          under which Company uses and discloses that
                          information, and your rights in relation to that
                          information. Changes to this Privacy Policy are
                          discussed at the end of this document. Each time you
                          use the Website, however, the current version of this
                          Privacy Policy will apply. Accordingly, each time you
                          use the Website you should check the date of this
                          Privacy Policy (which appears at the beginning of this
                          document) and review any changes since the last time
                          you used the Website.
                        </p>
                        <p className="AI-paragraph">
                          Our Website is hosted in the United States of America
                          and is subject to U.S. state and federal law. If you
                          are accessing our Website from other jurisdictions,
                          please be advised that you are transferring your
                          personal information to us in the United States, and
                          by using our Website, you consent to that transfer and
                          use of your personal information in accordance with
                          this Privacy Policy. You also agree to abide by the
                          applicable laws of applicable states and U.S. federal
                          law concerning your use of the Website and your
                          agreements with us. Any persons accessing our Website
                          from any jurisdiction with laws or regulations
                          governing the use of the Internet, including personal
                          data collection, use and disclosure different from
                          those of the jurisdictions mentioned above may only
                          use the Website in a manner lawful in their
                          jurisdiction. If your use of our Website would be
                          unlawful in your jurisdiction, please do not use the
                          Website.
                        </p>
                        <p className="AI-paragraph">
                          BY USING OR ACCESSING THE WEBSITE, YOU ARE ACCEPTING
                          THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY.
                        </p>
                        <p className="AI-paragraph">
                          <span className="text-style ">CHILDREN</span>
                        </p>
                        <p className="AI-paragraph">
                          The Children's Online Privacy Protection Act ("COPPA")
                          protects the online privacy of children under 13 years
                          of age. We do not knowingly collect or maintain
                          personal information from anyone under the age of 13,
                          unless or except as permitted by law. Any person who
                          provides personal information through the Website
                          represents to us that he or she is 13 years of age or
                          older. If we learn that personal information has been
                          collected from a user under 13 years of age on or
                          through the Website, then we will take the appropriate
                          steps to cause this information to be deleted. If you
                          are the parent or legal guardian of a child under 13
                          who has become a member of the Website or has
                          otherwise transferred personal information to the
                          Website, please contact Company using our contact
                          information below to have that child's account
                          terminated and information deleted.
                        </p>
                        <p className="AI-paragraph">
                          <span className="text-style ">
                            GATHERING, USE AND DISCLOSURE OF
                            NON-PERSONALLY-IDENTIFYING INFORMATION
                          </span>
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style">
                            Users of the Website Generally
                          </i>
                        </p>

                        <p className="AI-paragraph">
                          “Non-Personally-Identifying Information” is
                          information that, without the aid of additional
                          information, cannot be directly associated with a
                          specific person. “Personally-Identifying Information,”
                          by contrast, is information such as a name or email
                          address that, without more, can be directly associated
                          with a specific person. Like most website operators,
                          Company gathers from users of the Website
                          Non-Personally-Identifying Information of the sort
                          that Web browsers, depending on their settings, may
                          make available. That information includes the user’s
                          Internet Protocol (IP) address, operating system and
                          browser type, and the locations of the Web pages the
                          user views right before arriving at, while navigating
                          and immediately after leaving the Website. Although
                          such information is not personally identifiable, it
                          may be possible for Company to determine from an IP
                          address a user’s Internet service provider and the
                          geographic location of the visitor’s point of
                          connectivity as well as other statistical usage data.
                          Company analyzes Non-Personally-Identifying
                          Information gathered from users of the Website to help
                          Company better understand how the Website is being
                          used. By identifying patterns and trends in usage,
                          Company is able to better design the Website to
                          improve users’ experiences, both in terms of content
                          and ease of use. From time to time, Company may also
                          release the Non-Personally-Identifying Information
                          gathered from Website users in the aggregate, such as
                          by publishing a report on trends in the usage of the
                          Website.
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style ">Web Cookies</i>
                        </p>

                        <p className="AI-paragraph">
                          A “Web Cookie” is a string of information which
                          assigns you a unique identification that a website
                          stores on a user’s computer, and that the user’s
                          browser provides to the website each time the user
                          submits a query to the site. We use cookies on the
                          Website to keep track of services you have used, to
                          record registration information regarding your login
                          name and password, to record your user preferences, to
                          keep you logged into the Website, and to facilitate
                          purchase procedures. Company also uses Web Cookies to
                          track the pages that users visit during each Website
                          session, both to help Company improve users’
                          experiences and to help Company understand how the
                          Website is being used. As with other
                          Non-Personally-Identifying Information gathered from
                          users of the Website, Company analyzes and discloses
                          in aggregated form information gathered using Web
                          Cookies, so as to help Company, its partners and
                          others better understand how the Website is being
                          used. WEBSITE USERS WHO DO NOT WISH TO HAVE WEB
                          COOKIES PLACED ON THEIR COMPUTERS SHOULD SET THEIR
                          BROWSERS TO REFUSE WEB COOKIES BEFORE ACCESSING THE
                          WEBSITE, WITH THE UNDERSTANDING THAT CERTAIN FEATURES
                          OF THE WEBSITE MAY NOT FUNCTION PROPERLY WITHOUT THE
                          AID OF WEB COOKIES. WEBSITE USERS WHO REFUSE WEB
                          COOKIES ASSUME ALL RESPONSIBILITY FOR ANY RESULTING
                          LOSS OF FUNCTIONALITY.
                        </p>

                        <p className="AI-paragraph">
                          <i className="text-style ">Third Party Advertisers</i>
                        </p>

                        <p className="AI-paragraph">
                          We may use third-party advertising companies to serve
                          ads when you visit the Website. These companies may
                          use information (not including any
                          Personally-Identifying Information) about your visits
                          to this and other websites that are contained in Web
                          Cookies in order to provide advertisements about goods
                          and services of interest to you. Using a tool created
                          by the Network Advertising Initiative, you can opt out
                          of several third party ad servers’ and networks’ Web
                          Cookies simultaneously. If you would like more
                          information about this practice and to know your
                          choices about not having this information used by
                          these companies, please follow the following links:
                          http://networkadvertising.org/managing/opt_out.asp,
                          http://preferences-mgr.truste.com/, or
                          http://www.aboutads.info/choices/. Please contact us
                          if you would like to know the identity of the
                          third-party advertising companies we are currently
                          using to serve ads.
                        </p>
                        <p className="AI-paragraph">
                          We may allow advertisers to choose the characteristics
                          of users who will see their advertisements and we may
                          use any of the non-personally identifiable attributes
                          we have collected (including information you may have
                          decided not to show to other users, such as your birth
                          year or other sensitive personal information or
                          preferences) to select the appropriate audience for
                          those advertisements. We do not identify you to the
                          advertiser.
                        </p>

                        <p className="AI-paragraph">
                          <i className="text-style ">Web Beacons</i>
                        </p>
                        <p className="AI-paragraph">
                          A “Web Beacon” is an object that is embedded in a web
                          page or email that is usually invisible to the user
                          and allows website operators to check whether a user
                          has viewed a particular web page or an email. Company
                          may use Web Beacons on the Website and in emails to
                          count users who have visited particular pages, viewed
                          emails, and to deliver co-branded services. Web
                          Beacons are not used to access users’
                          Personally-Identifying Information; they are a
                          technique Company may use to compile aggregated
                          statistics about Website usage. Web Beacons collect
                          only a limited set of information including a Web
                          Cookie number, time and date of a page or email view,
                          and a description of the page or email on which the
                          Web Beacon resides. You may not decline Web Beacons,
                          however, they can be rendered ineffective by declining
                          all Web Cookies or modifying your browser setting to
                          notify you each time a Web Cookie is tendered and
                          permit you to accept or decline Web Cookies on an
                          individual basis.{" "}
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style ">Analytics</i>
                        </p>

                        <p className="AI-paragraph">
                          We may use third-party vendors, including Google, who
                          use first-party cookies (such as the Google Analytics
                          cookie) and third-party cookies (such as the
                          DoubleClick cookie) together to provide analytics
                          services, inform, optimize, and serve ads based on
                          your past activity on our websites and applications,
                          including Google Analytics for Display Advertising.
                          These vendors may use Web Cookies, Web Beacons and
                          other technologies to collect information about your
                          use of the Website, our service and other websites,
                          including your IP address, web browser, pages viewed,
                          time spent on pages, links clicked and conversion
                          information. This information may be used by us and
                          others to, among other things, analyze and track data,
                          determine the popularity of certain content, deliver
                          advertising and content targeted to your interests on
                          our service and other websites and better understand
                          your online activity. If you do not want any
                          information to be collected and used by Google
                          Analytics, you can install an opt-out in your web
                          browser (https://tools.google.com/dlpage/gaoptout/)
                          and/or opt out from Google Analytics for Display
                          Advertising or the Google Display Network. You can do
                          so by using Google’s Ads Settings
                          (www.google.com/settings/ads). For more information
                          about interest-based ads, or to opt out in general of
                          having your web browsing information used for
                          behavioral advertising purposes, please visit
                          www.aboutads.info/choices.
                        </p>

                        <p className="AI-paragraph">
                          <i className="text-style ">
                            Aggregated and Non-Personally-Identifying
                            Information
                          </i>
                        </p>

                        <p className="AI-paragraph">
                          We may share aggregated and Non-Personally Identifying
                          Information we collect under any of the above
                          circumstances. We may also share it with third parties
                          and our affiliate companies to develop and deliver
                          targeted advertising on our Website and on websites of
                          third parties. We may combine Non-Personally
                          Identifying Information we collect with additional
                          Non-Personally Identifying Information collected from
                          other sources. We also may share aggregated
                          information with third parties, including advisors,
                          advertisers and investors, for the purpose of
                          conducting general business analysis. For example, we
                          may tell our advertisers the number of visitors to our
                          Website and the most popular features or services
                          accessed. This information does not contain any
                          Personally-Identifying Information and may be used to
                          develop website content and services that we hope you
                          and other users will find of interest and to target
                          content and advertising.
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style ">
                            Mobile Device Additional Terms
                          </i>
                        </p>

                        <p className="AI-paragraph">
                          <ul>
                            <li>
                              <u>Mobile Device.</u> If you use a mobile device
                              to access our Website or download any of our
                              applications, we may collect device information
                              (such as your mobile device ID, model and
                              manufacturer), operating system and version
                              information, and IP address.
                            </li>
                            <li>
                              <u>Geo-Location Information.</u> Unless we have
                              received your prior consent, we do not access or
                              track any location-based information from your
                              mobile device at any time while downloading or
                              using our mobile applications or our services
                              except that it may be possible for Company to
                              determine from an IP address the geographic
                              location of the user’s point of connectivity, in
                              which case we may gather and use such general
                              location data.
                            </li>
                            <li>
                              <u>Push Notifications.</u> We send you push
                              notifications if you choose to receive them
                              letting you know when someone has sent you a
                              message, or for other service-related matters. If
                              you wish to opt-out from receiving these types of
                              communications you may turn them off at the device
                              level.
                            </li>
                            <li>
                              <u>Mobile Analytics.</u>We use mobile analytics
                              software to allow us to better understand the
                              functionality of our mobile software on your
                              phone. This software may record information such
                              as how often you use the application, the events
                              that occur within the application, aggregated
                              usage, performance data, and where the application
                              was downloaded from. We do not link the
                              information we store within the analytics software
                              to any Personally-Identifying Information you
                              submit within the mobile application.
                            </li>
                          </ul>
                        </p>

                        <p className="AI-paragraph">
                          <span className="text-style">
                            COLLECTION, USE AND DISCLOSURE OF
                            PERSONALLY-IDENTIFYING INFORMATION
                          </span>
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style">Website Registration</i>
                        </p>

                        <p className="AI-paragraph">
                          As defined above, Personally-Identifying Information
                          is information that can be directly associated with a
                          specific person. Company may collect a range of
                          Personally-Identifying Information from and about
                          Website users. Much of the Personally-Identifying
                          Information collected by Company about users is
                          information provided by users themselves when (1)
                          registering for our service, (2) logging in with
                          social network credentials, (3) participating in
                          polls, surveys or other features of our service, or
                          responding to offers or advertisements, (4)
                          communicating with us, (5) creating a public profile,
                          or (6) signing up to receive newsletters. That
                          information may include each user’s name, address,
                          email address, and telephone number, and, if you
                          transact business with us, financial information such
                          as your payment method (valid credit card number,
                          type, expiration date or other financial information).
                          We also may request information about your interests
                          and activities, your gender, age, date of birth,
                          username, hometown and other demographic information,
                          and other relevant information as determined by
                          Company from time to time. Users of the Website are
                          under no obligation to provide Company with
                          Personally-Identifying Information of any kind, with
                          the caveat that a user’s refusal to do so may prevent
                          the user from using certain Website features.
                        </p>

                        <p className="AI-paragraph">
                          BY REGISTERING WITH OR USING THE WEBSITE, YOU CONSENT
                          TO THE USE AND DISCLOSURE OF YOUR PERSONALLY
                          IDENTIFYING INFORMATION AS DESCRIBED IN THIS
                          “COLLECTION, USE AND DISCLOSURE OF
                          PERSONALLY-IDENTIFYING INFORMATION” SECTION.
                        </p>

                        <p className="AI-paragraph">
                          <i className="text-style">Company Communications</i>
                        </p>

                        <p className="AI-paragraph">
                          We may occasionally use your name and email address to
                          send you notifications regarding new services offered
                          by the Website that we think you may find valuable. We
                          may also send you service-related announcements from
                          time to time through the general operation of the
                          service. Generally, you may opt out of such emails at
                          the time of registration or through your account
                          settings, though we reserve the right to send you
                          notices about your account, such as service
                          announcements, and administrative messages, even if
                          you opt out of all voluntary email notifications.
                        </p>
                        <p className="AI-paragraph">
                          BY SIGNING UP FOR AN ACCOUNT AND PROVIDING YOUR PHONE
                          NUMBER, YOU AGREE TO RECEIVE INFORMATIONAL TEXT
                          MESSAGES FROM US RELATING TO USE OF THE COMPANY
                          SERVICE.
                        </p>
                        <p className="AI-paragraph">
                          If you do not want to receive such messages, you may
                          opt out or change your preferences in your account.
                          Opting out may prevent you from receiving messages
                          regarding updates, improvements, or offers.
                        </p>

                        <p className="AI-paragraph">
                          <i className="text-style">Company Disclosures</i>
                        </p>
                        <p className="AI-paragraph">
                          Company will disclose Personally-Identifying
                          Information under the following circumstances:
                        </p>
                        <p className="AI-paragraph">
                          <ul>
                            <li>
                              <span className="text-style">
                                By Law or to Protect Rights.
                              </span>{" "}
                              When we believe disclosure is appropriate in
                              connection with efforts to investigate, prevent,
                              or take other action regarding illegal activity,
                              suspected fraud or other wrongdoing; to protect
                              and defend the rights, property or safety of our
                              Company, our users, our employees, or others; to
                              comply with applicable law or cooperate with law
                              enforcement; or to enforce our Terms of Use or
                              other agreements or policies, in response to a
                              subpoena or similar investigative demand, a court
                              order, or a request for cooperation from a law
                              enforcement or other government agency; to
                              establish or exercise our legal rights; to defend
                              against legal claims; or as otherwise required by
                              law. In such cases, we may raise or waive any
                              legal objection or right available to us.
                            </li>
                            <li>
                              <span className="text-style">
                                Marketing Communications.
                              </span>{" "}
                              Unless users opt-out from receiving Company
                              marketing materials upon registration, Company may
                              email users about products and services that
                              Company believes may be of interest to them. If a
                              user wishes to opt-out of receiving marketing
                              materials from Company, user may do so by
                              following the unsubscribe link in email
                              communications, by going to your account settings
                              (if applicable) or contacting us using the contact
                              information below.
                            </li>

                            <li>
                              <span className="text-style">
                                Third Party Service Providers.
                              </span>{" "}
                              We may share your Personally-Identifying
                              Information, which may include your name and
                              contact information (including email address) with
                              our authorized service providers that perform
                              certain services on our behalf. These services may
                              include fulfilling orders, providing customer
                              service and marketing assistance, performing
                              business and sales analysis, supporting our
                              website functionality, and supporting contests,
                              sweepstakes, surveys and other features offered
                              through our Website. We may also share your name,
                              contact information and credit card information
                              with our authorized service providers who process
                              credit card payments. These service providers may
                              have access to personal information needed to
                              perform their functions but are not permitted to
                              share or use such information for any other
                              purpose.
                            </li>

                            <li>
                              <span className="text-style">
                                Business Transfers; Bankruptcy.
                              </span>{" "}
                              Company reserves the right to transfer all
                              Personally-Identifying Information in its
                              possession to a successor organization in the
                              event of a merger, acquisition, or bankruptcy or
                              other sale of all or a portion of Company’s
                              assets. Other than to the extent ordered by a
                              bankruptcy or other court, the use and disclosure
                              of all transferred Personally-Identifying
                              Information will be subject to this Privacy
                              Policy, or to a new privacy policy if you are
                              given notice of that new privacy policy and are
                              given an opportunity to affirmatively opt-out of
                              it. Personally-Identifying Information submitted
                              or collected after a transfer, however, may be
                              subject to a new privacy policy adopted by the
                              successor organization.
                            </li>
                          </ul>
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style">
                            Changing Personally-Identifying Information; Account
                            Termination
                          </i>
                        </p>
                        <p className="AI-paragraph">
                          You may at any time review or change your
                          Personally-Identifying Information by going to your
                          account settings (if applicable) or contacting us
                          using the contact information below. Upon your
                          request, we will deactivate or delete your account and
                          contact information from our active databases. Such
                          information will be deactivated or deleted as soon as
                          practicable based on your account activity and
                          accordance with our deactivation policy and applicable
                          law. To make this request, either go to your account
                          settings (if applicable) or contact us as provided
                          below. We will retain in our files some personal
                          information to prevent fraud, to troubleshoot
                          problems, to assist with any investigations, to
                          enforce our Terms of Use and to comply with legal
                          requirements as is permitted by law. Therefore, you
                          should not expect that all your personal information
                          will be completely removed from our databases in
                          response to your requests. Additionally, we keep a
                          history of changed information to investigate
                          suspected fraud with your account.
                        </p>
                        <p className="AI-paragraph">
                          <i className="text-style">General Use</i>
                        </p>
                        <p className="AI-paragraph">
                          We use the Personally-Identifying Information in the
                          file we maintain about you, and other information we
                          obtain from your current and past activities on the
                          Website to: deliver the products and services that you
                          have requested, manage your account and provide you
                          with customer support, communicate with you by email,
                          postal mail, telephone and/or mobile devices about
                          products or services that may be of interest to you
                          either from us, our affiliate companies or other third
                          parties, develop and display content and advertising
                          tailored to your interests on our Website and other
                          sites, resolve disputes, troubleshoot problems,
                          measure consumer interest in our services, inform you
                          of updates, customize your experience, detect and
                          protect us against error, fraud and other criminal
                          activity, enforce our Terms of Use, and as otherwise
                          described to you at the time of collection. At times,
                          we may look across multiple users to identify
                          problems. In particular, we may examine your
                          Personally-Identifying Information to identify users
                          using multiple user IDs or aliases. We may compare and
                          review your Personally-Identifying Information for
                          accuracy and to detect errors and omissions. We may
                          use financial information or payment method to process
                          payment for any purchases made on our Website, enroll
                          you in the discount, rebate, and other programs in
                          which you elect to participate, to protect against or
                          identify possible fraudulent transactions, and
                          otherwise as needed to manage our business.
                        </p>

                        <p className="AI-paragraph">
                          <span className="text-style">
                            COLLECTION AND USE OF INFORMATION BY THIRD PARTIES
                            GENERALLY
                          </span>
                        </p>
                        <p className="AI-paragraph">
                          Company contractually prohibits its contractors,
                          affiliates, vendors and suppliers from disclosing
                          Personally-Identifying Information received from
                          Company, other than in accordance with this Privacy
                          Policy. Third parties are under no obligation to
                          comply with this Privacy Policy, however, with respect
                          to Personally-Identifying Information that users
                          provide directly to those third parties or that those
                          third parties collect for themselves. These third
                          parties include advertisers, providers of games,
                          utilities, widgets and a variety of other third party
                          applications accessible through the Website. Company
                          neither owns nor controls the third-party websites and
                          applications accessible through the Website. Thus,
                          this Privacy Policy does not apply to information
                          provided to or gathered by the third parties that
                          operate them. Before visiting a third-party, or using
                          a third party application, whether by means of a link
                          on the Website, directly through the Website, or
                          otherwise, and before providing any
                          Personally-Identifying Information to any such third
                          party, users should inform themselves of the privacy
                          policies and practices (if any) of the third party
                          responsible for that website or application, and
                          should take those steps necessary to, in those users’
                          discretion, protect their privacy.
                        </p>
                        <p className="AI-paragraph">
                          <span className="text-style">SECURITY</span>
                        </p>
                        <p className="AI-paragraph">
                          We take security of your Personally-Identifying
                          Information seriously and use reasonable electronic,
                          personnel, and physical measures to protect it from
                          loss, theft, alteration, or misuse. However, please be
                          advised that even the best security measures cannot
                          fully eliminate all risks. We cannot guarantee that
                          only authorized persons will view your information. We
                          are not responsible for third party circumvention of
                          any privacy settings or security measures.
                        </p>
                        <p className="AI-paragraph">
                          We are dedicated to protect all information on our
                          Website as is necessary. However, you are responsible
                          for maintaining the confidentiality of your
                          Personally-Identifying Information by keeping your
                          password confidential. You should change your password
                          immediately if you believe someone has gained
                          unauthorized access to it or your account. If you lose
                          control of your account, you should notify us
                          immediately.
                        </p>
                        <p className="AI-paragraph">
                          <span className="text-style">
                            PRIVACY POLICY CHANGES
                          </span>
                        </p>
                        <p className="AI-paragraph">
                          Company may, in its sole discretion, change this
                          Privacy Policy from time to time. Any and all changes
                          to Company’s Privacy Policy will be reflected on this
                          page and the date new versions are posted will be
                          stated at the top of this Privacy Policy. Unless
                          stated otherwise, our current Privacy Policy applies
                          to all information that we have about you and your
                          account. Users should regularly check this page for
                          any changes to its Privacy Policy. Company will always
                          post new versions of the Privacy Policy on the
                          Website. However, Company may, as determined in its
                          discretion, decide to notify users of changes made to
                          this Privacy Policy via email or otherwise.
                          Accordingly, it is important that users always
                          maintain and update their contact information.
                        </p>

                        <p className="AI-paragraph">
                          <span className="text-style">
                            CALIFORNIA PRIVACY RIGHTS
                          </span>
                        </p>

                        <p className="AI-paragraph">
                          California Civil Code Section 1798.83, also known as
                          the "Shine The Light" law, permits our users who are
                          California residents to request and obtain from us
                          once a year, free of charge, information about the
                          personal information (if any) we disclosed to third
                          parties for direct marketing purposes in the preceding
                          calendar year. If applicable, this information would
                          include a list of the categories of personal
                          information that was shared and the names and
                          addresses of all third parties with which we shared
                          information in the immediately preceding calendar
                          year. If you are a California resident and would like
                          to make such a request, please submit your request in
                          writing to our privacy officer using contact
                          information found on the Website.
                        </p>

                        <p className="AI-paragraph">
                          <span className="text-style">
                            DO NOT TRACK POLICY
                          </span>
                        </p>
                        <p className="AI-paragraph">
                          Our Website does not respond to “Do Not Track” signals
                          or mechanisms.
                        </p>
                        <p className="AI-paragraph">
                          <span className="text-style">CONTACT</span>
                        </p>

                        <p className="AI-paragraph">
                          If you have any questions regarding our Privacy
                          Policy, please contact our privacy officer using our
                          contact information located on the Website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page_divider_banner">
          <div className="">
            <h3 className="Proud-Member-of-the-Following-Organizations p-4">
              Proud <span className="color_color1">Member</span> of the
              Following Organizations:
            </h3>
            <div className="row no-gutters justify-content-start">
              <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                <div className="row justify-content-end">
                  <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                    <img
                      src={shape_19}
                      className="image image_PML_img"
                      alt=""
                    />
                    <img src={shape_20} className="image image_w100" alt="" />
                    <img src={shape_21} className="image image_w150" alt="" />
                    <img src={shape_22} className="image image_w160" alt="" />
                    <img src={shape_23} className="image image_w100" alt="" />
                    <img src={shape_24} className="image image_w100" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="d-flex justify-content-around align-items-end">
                  <img src={shape_19} className="image image_w100" alt="" />
                  <img src={shape_20} className="image image_w100" alt="" />
                  <img src={shape_21} className="image image_w150" alt="" />
                </div>
                <div className="d-flex justify-content-around align-items-end">
                  <img src={shape_22} className="image image_w160" alt="" />
                  <img src={shape_23} className="image image_w100" alt="" />
                  <img src={shape_24} className="image image_w100" alt="" />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PrivacyPage);
