import React from "react";
import WhyTab from "../../assets/images/howPage-whyTab.svg";
import HowTab from "../../assets/images/howPage-whatTab.svg";
import whatTab from "../../assets/images/whyPage-whyTab.svg";
import Step1 from "../../assets/images/Step1.svg";
import MobileStep1 from "../../assets/images/MobileStep1.svg";
import Arrow1 from "../../assets/images/Arrow1.svg";
import Arrow2 from "../../assets/images/Arrow2.svg";
import Step2 from "../../assets/images/HiringProfile.svg";
import Step3 from "../../assets/images/Step3.svg";
import Arrow3 from "../../assets/images/Arrow3.svg";
import Step4 from "../../assets/images/Step4.svg";
import Step5 from "../../assets/images/Step5.svg";
import Step6 from "../../assets/images/TurboBlue.svg";
import Arrow4 from "../../assets/images/Arrow4.svg";
import Mobile_Arrow from "../../assets/images/ArrowForMobileVersion.svg";
// import shape_19 from "../../assets/images/shape-19.png";
// import shape_20 from "../../assets/images/shape-20.png";
// import shape_21 from "../../assets/images/shape-21.png";
// import shape_22 from "../../assets/images/shape-22.png";
// import shape_23 from "../../assets/images/shape-23.png";
// import shape_24 from "../../assets/images/shape-24.png";
import Impact from "../../assets/images/impact-gif.gif";
import Impact2 from "../../assets/images/impact-gif2.gif";
import ArrowRight from "../../assets/images/arrow.gif";
import Star1 from "../../assets/images/star-2.svg";
import Star2 from "../../assets/images/star-2.svg";
import Star3 from "../../assets/images/star-2.svg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SubNavigation from "../../components/Sub-navigation";
import { Link } from "react-router-dom";

import { getFromStorage, removeFromStorage } from "../../common/service";
import { CONSTANTS } from "../../constants";
import {
  API_ENDPOINTS,
  AUTH_HEADER_KEY,
  PLAIN_HEADERS,
} from "../../constants/api-endpoints";
import { apiCall } from "../../common/NetworkCalls";
import { withRouter } from "react-router-dom";

//Guy Animation
// import Guy1 from "../../assets/images/Animation/GuyAnimation/GuyAnimation1.svg";
// import Guy2 from "../../assets/images/Animation/GuyAnimation/GuyAnimation2.svg";
// import Guy3 from "../../assets/images/Animation/GuyAnimation/GuyAnimation3.svg";
// import Guy4 from "../../assets/images/Animation/GuyAnimation/GuyAnimation4.svg";
// import Guy5 from "../../assets/images/Animation/GuyAnimation/GuyAnimation5.svg";
// import Guy6 from "../../assets/images/Animation/GuyAnimation/GuyAnimation6.svg";
// import Guy7 from "../../assets/images/Animation/GuyAnimation/GuyAnimation7.svg";
// import Guy8 from "../../assets/images/Animation/GuyAnimation/GuyAnimation8.svg";
// import Guy9 from "../../assets/images/Animation/GuyAnimation/GuyAnimation9.svg";
// import Guy10 from "../../assets/images/Animation/GuyAnimation/GuyAnimation10.svg";
// import Guy11 from "../../assets/images/Animation/GuyAnimation/GuyAnimation11.svg";
// import Guy12 from "../../assets/images/Animation/GuyAnimation/GuyAnimation12.svg";
// import Guy13 from "../../assets/images/Animation/GuyAnimation/GuyAnimation13.svg";
// import Guy14 from "../../assets/images/Animation/GuyAnimation/GuyAnimation14.svg";
// import Guy15 from "../../assets/images/Animation/GuyAnimation/GuyAnimation15.svg";
// import Guy16 from "../../assets/images/Animation/GuyAnimation/GuyAnimation16.svg";
// import Guy17 from "../../assets/images/Animation/GuyAnimation/GuyAnimation17.svg";
// import Guy18 from "../../assets/images/Animation/GuyAnimation/GuyAnimation18.svg";
import Guy19 from "../../assets/images/Animation/GuyAnimation/GuyAnimation19.svg";

class WhatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: false,
      userInfo: "",
    };
  }

  componentDidMount() {
    var userData = getFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
    if (userData) {
      userData = JSON.parse(userData);
      this.setState({ isUserLoggedIn: true, userInfo: userData });
    }
  }

  logOutUser() {
    this.setState({ isUserLoggedIn: false, userInfo: "" });
    removeFromStorage(CONSTANTS.STORAGE_KEYS.USER_INFO);
    var headers = PLAIN_HEADERS;
    headers[AUTH_HEADER_KEY] = `Bearer ${this.state.userInfo.authToken}`;
    return apiCall(API_ENDPOINTS.LOGOUT, "GET", "")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="what-page about-page">
        <Header
          isUserLoggedIn={this.state.isUserLoggedIn}
          userInfo={this.state.userInfo}
          logOutUser={() => {
            this.logOutUser();
          }}
        />
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active trusteddev-tab"
            id="trustedDev"
            role="tabpanel"
            aria-labelledby="trustedDev-tab"
          >
            <div className="what-page-banner">
              <SubNavigation
                isUserLoggedIn={this.state.isUserLoggedIn}
                userInfo={this.state.userInfo}
                logOutUser={() => {
                  this.logOutUser();
                }}
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-12 nav-pill-position">
              <div className="row justify-content-center no-gutters">
                <nav className="nav nav-pills nav-justified">
                  <Link className="nav-link nav-pill" to={"why"}>
                    <img src={WhyTab} alt="why-tab" className="header-tab" />
                    <label className="whypage-tabLabel color_white">Why?</label>
                  </Link>
                  <Link className="nav-link nav-pill" to={"how"}>
                    <img src={HowTab} alt="how-tab" className="header-tab" />
                    <label className="whypage-tabLabel color_page3_tab2">
                      How?
                    </label>
                  </Link>
                  <Link className="nav-link nav-pill" to={"what"}>
                    <img
                      src={whatTab}
                      alt="what-tab"
                      className="header-tab active-tab"
                    />
                    <label className="whypage-tabLabel color_color6">
                      What?
                    </label>
                  </Link>
                </nav>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 overflowX">
              <div
                className="card text-center card_border bg_transperant wow"
              >
                <div className="card-header card_border bg_sky_blue">
                  <div className="offset-xl-3 col-xl-9 offset-lg-3 col-lg-9 offset-md-3 col-md-9 col-sm-12">
                    <h2
                      className="what-trustedDev"
                    >
                      What?
                    </h2>
                  </div>
                </div>
                <div className="card-body card_body_color">
                  <div className="row no-gutters">
                    <div className="offset-xl-4 col-xl-8 offset-lg-2 col-lg-10 offset-md-2 col-md-10 col-sm-12">
                      <div className="row no-gutters">
                        <div className="col-md-12">
                          <p
                            className="what-content"
                          >
                            <span className="text-style">
                              The Trusted Dev team is passionate about helping
                              enterprises
                            </span>{" "}
                            vet IT technical software development resources
                            prior to making a costly hiring decision.
                          </p>
                          <p
                            className="what-content"
                          >
                            For over a decade our leadership team has
                            experienced the perils and losses associated with
                            onboarding seemingly highly competent software
                            engineers only to discover the lack of fit once on
                            the job. Only after implementing our
                            <span className="text-style">
                              {" "}
                              rigorous vetting methodology
                            </span>{" "}
                            were we able to
                            <span className="text-style">
                              {" "}
                              source elite IT software development talent with
                              predictable results.
                            </span>
                          </p>
                          <p
                            className="what-content"
                          >
                            This is a big problem that can be mitigated by
                            <span className="text-style">
                              {" "}
                              utilizing the Trusted Dev Portal to augment your
                              existing enterprise software development team.
                              Leverage our deep expertise
                            </span>{" "}
                            in software development resource vetting to
                            <span className="text-style">
                              {" "}
                              reduce the risk
                            </span>{" "}
                            of turnover within your software development team.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Expert-software-engineer-background-banner">
            <div className="row no-gutters Expert-Software-Engineer-Card">
              <div className="offset-md-3 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 overflowX">
                <div
                  className="card text-center card_border bg_transperant wow"
                >
                  <div className="card-header Expert-Software-card-header card_border">
                    <div className="col-12">
                      <h2
                        className="what-trustedDev what-trustedDevLeft"
                      >
                        <span className="text-style-1">Expert</span> Software
                        Engineer Vetting Based on Your{" "}
                        <span className="text-style-2">Hiring Profile</span>
                      </h2>
                    </div>
                  </div>
                  <div className="card-body Expert-software-engineer-section-body">
                    <div className="row no-gutters">
                      <div className="d-md-none d-lg-none d-xl-none">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 gif-padding">
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div
                                className="d-flex"
                              >
                                <img
                                  src={Impact}
                                  alt=""
                                  className="impact-gif"
                                />
                                <div className="d-block">
                                  <img
                                    src={ArrowRight}
                                    alt=""
                                    className="arrow-right"
                                  />
                                </div>
                                <div className="d-block">
                                  <img
                                    src={Star1}
                                    alt=""
                                    className="star star1"
                                  />
                                  <img
                                    src={Star2}
                                    alt=""
                                    className="star star2"
                                  />
                                  <img
                                    src={Star3}
                                    alt=""
                                    className="star star3"
                                  />
                                </div>
                                <img
                                  src={Impact2}
                                  alt=""
                                  className="impact2-gif"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-md-block d-lg-block d-xl-block">
                        <div className="position-relative">
                          <div className="Guy_Animation">
                            {/* <img
                              src={Guy1}
                              className="position-absolute"
                              alt=""
                              id="guy1"
                            /> */}
                            {/* <img
                              src={Guy2}
                              className="position-absolute"
                              alt=""
                              id="guy2"
                            />
                            <img
                              src={Guy3}
                              className="position-absolute"
                              alt=""
                              id="guy3"
                            />
                            <img
                              src={Guy4}
                              className="position-absolute"
                              alt=""
                              id="guy4"
                            />
                            <img
                              src={Guy5}
                              className="position-absolute"
                              alt=""
                              id="guy5"
                            />
                            <img
                              src={Guy6}
                              className="position-absolute"
                              alt=""
                              id="guy6"
                            />
                            <img
                              src={Guy7}
                              className="position-absolute"
                              alt=""
                              id="guy7"
                            />
                            <img
                              src={Guy8}
                              className="position-absolute"
                              alt=""
                              id="guy8"
                            />
                            <img
                              src={Guy9}
                              className="position-absolute"
                              alt=""
                              id="guy9"
                            />
                            <img
                              src={Guy10}
                              className="position-absolute"
                              alt=""
                              id="guy10"
                            />
                            <img
                              src={Guy11}
                              className="position-absolute"
                              alt=""
                              id="guy11"
                            />
                            <img
                              src={Guy12}
                              className="position-absolute"
                              alt=""
                              id="guy12"
                            />
                            <img
                              src={Guy13}
                              className="position-absolute"
                              alt=""
                              id="guy13"
                            />
                            <img
                              src={Guy14}
                              className="position-absolute"
                              alt=""
                              id="guy14"
                            />
                            <img
                              src={Guy15}
                              className="position-absolute"
                              alt=""
                              id="guy15"
                            />
                            <img
                              src={Guy16}
                              className="position-absolute"
                              alt=""
                              id="guy16"
                            />
                            <img
                              src={Guy17}
                              className="position-absolute"
                              alt=""
                              id="guy17"
                            />
                            <img
                              src={Guy18}
                              className="position-absolute"
                              alt=""
                              id="guy18"
                            />*/}
                            <img
                              src={Guy19}
                              className="position-absolute"
                              alt=""
                              id="guy19"
                            /> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-xl-10 col-lg-11 col-md-11 col-sm-12 col-xs-12 overflowX">
              <div
                className="card text-center card_border bg_transperant wow"
              >
                <div className="card-header World-Class-Technical-Recruitment-Border World-Class-Technical-Recruitment-Card">
                  <div className="offset-xl-4 col-xl-8 offset-lg-2 col-lg-9 offset-md-2 col-md-10 col-sm-12">
                    <h2 className="what-trustedDev">
                      A <span className="text-style-1">World Class</span>{" "}
                      Technical Recruitment Life Cycle Methodology
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-section">
            <div className="row">
              <div className="col-md-12 step-section">
                {/* step 1 */}
                <img
                  src={Step1}
                  alt=""
                  className="d-none d-lg-block d-xl-block step-img step-img-step1"
                />
                <img
                  src={MobileStep1}
                  alt=""
                  className="d-lg-none d-xl-none step-img step-img-step1"
                />
                <span
                  className="step-label step-label-step1"
                >
                  STEP
                </span>
                <p
                  className="step-content step-content-step1"
                >
                  You register a new Trusted Dev Portal account
                </p>
                <img
                  src={Arrow1}
                  alt=""
                  className="step-arrow step-arrow-step1"
                />
                <img
                  src={Mobile_Arrow}
                  alt=""
                  className="step-arrow mobile-step1 wow"
                />

                {/* step 2 */}
                <img
                  src={Mobile_Arrow}
                  alt=""
                  className="step-arrow mobile-step2"
                />
                <img
                  src={Arrow2}
                  alt=""
                  className="step-arrow step-arrow-step2"
                />
                <span
                  className="step-label step-label-step2"
                >
                  STEP
                </span>
                <p
                  className="step-content step-content-step2"
                >
                  Your software development hiring profile is generated
                </p>
                <img
                  src={Step2}
                  className="step-img step-img-step2"
                  alt=""
                />

                {/* step 3 */}
                <p
                  className="step-content step-content-step3"
                >
                  Pre-vetted software development talent is curated based on your hiring profile
                </p>
                <span
                  className="step-label step-label-step3"
                >
                  STEP
                </span>
                <img
                  src={Step3}
                  className="step-img step-img-step3"
                  alt=""
                />
                <img
                  src={Arrow3}
                  className="step-arrow step-arrow-step3"
                  alt=""
                />
                <img
                  src={Mobile_Arrow}
                  alt=""
                  className="step-arrow mobile-step3"
                />

                {/* step 4 */}
                <span
                  className="step-label step-label-step4"
                >
                  STEP
                </span>
                <img
                  src={Step4}
                  className="step-img step-img-step4"
                  alt=""
                />
                <img
                  src={Mobile_Arrow}
                  alt=""
                  className="step-arrow mobile-step4"
                />
                <p
                  className="step-content step-content-step4"
                >
                  You choose the elite software development resource that will join your internal team!
                </p>
                <img
                  src={Arrow4}
                  className="step-arrow step-arrow-step4"
                  alt=""
                />

                {/* step 5 */}
                <img
                  src={Step5}
                  className="step-img step-img-step5"
                  alt=""
                />
                <span
                  className="step-label step-label-step5"
                >
                  STEP
                </span>
                <p
                  className="step-content step-content-step5"
                >
                  We optimize your technical recruitment life cycle with AI,
                  machine learning and the human touch
                </p>
                {/* step 6 */}
               <p className="step-content step-content-step6">
              The cloud based Trusted Dev web portal turbo charges hiring managers ability to secure, hire, retain, menage projects, budget and compliance for elite IT contract to hire talent.
              </p>
              <span className="step-label step-label-step6">STEP</span>
              <img
                src={Step6}
                className="step-img step-img-step6"
                alt=""
              />
              <img
                src={Arrow3}
                className="step-arrow step-arrow-step5"
                alt=""
              />
              <img
                src={Mobile_Arrow}
                alt=""
                className="step-arrow mobile-step5"
              />
              </div>
            </div>
          </div>
          <div
            className="page_bottom_divider_banner wow"
          >
            {/* <h3
              className="Proud-Member-of-the-Following-Organizations p-4 wow"
            >
              Proud <span className="color_color1">Member</span> of the
              Following Organizations:
            </h3>
            <div
              className="row no-gutters justify-content-start wow"
            >
              <div className="col-xl-10 col-lg-10 col-md-10 logo_divider_row d-none d-md-block d-lg-block d-xl-block">
                <div className="row justify-content-end">
                  <div className="offset-xl-2 col-xl-push-8 offset-lg-3 col-lg-9  offset-md-3 col-md-9 d-flex justify-content-center align-items-center">
                    <img
                      src={shape_19}
                      className="image image_PML_img"
                      alt=""
                    />
                    <img
                      src={shape_20}
                      className="image image_w100"
                      alt=""
                    />
                    <img
                      src={shape_21}
                      className="image image_w150"
                      alt=""
                    />
                    <img
                      src={shape_22}
                      className="image image_w160"
                      alt=""
                    />
                    <img
                      src={shape_23}
                      className="image image_w100"
                      alt=""
                    />
                    <img
                      src={shape_24}
                      className="image image_w100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 logo_divider_row d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="d-flex justify-content-around align-items-end">
                  <img
                    src={shape_19}
                    className="image image_w100"
                    alt=""
                  />
                  <img
                    src={shape_20}
                    className="image image_w100"
                    alt=""
                  />
                  <img
                    src={shape_21}
                    className="image image_w150"
                    alt=""
                  />
                </div>
                <div className="d-flex justify-content-around align-items-end">
                  <img
                    src={shape_22}
                    className="image image_w160"
                    alt=""
                  />
                  <img
                    src={shape_23}
                    className="image image_w100"
                    alt=""
                  />
                  <img
                    src={shape_24}
                    className="image image_w100"
                    alt=""
                  />
                </div>
              </div>
            </div> */}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WhatPage);
