export const storeInStorage = (key, value) => {
    localStorage.setItem(btoa(key), btoa(value));
}

export const getFromStorage = (key) => {
    var data = localStorage.getItem(btoa(key));
    if(data){
        return atob(data);
    }
    return null;
}

export const removeFromStorage = (key) => {
    localStorage.removeItem(btoa(key));
}