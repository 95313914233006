import { RETRIEVED_SURVEY_DATA, FAILED_TO_RETRIEVE_SURVEY_DATA } from '../actions/home';

const INITIAL_STATE = {
    surveyData: [],
    message: ''
};

const homeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RETRIEVED_SURVEY_DATA:
            state = { ...state, surveyData: action.payload };
            return state;
        case FAILED_TO_RETRIEVE_SURVEY_DATA:
            state = { ...state, surveyData: [], message: action.payload };
            return state;
        default:
            return state;
    }
}

export default homeReducer;