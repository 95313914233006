import React, { useEffect } from "react";
import {
  Route,
  HashRouter as Router,
  Switch,
  Redirect,
  useLocation,
  withRouter,
} from "react-router-dom";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducers from "../reducers";
import thunk from "redux-thunk";
import logger from "redux-logger";

import HomePage from "../pages/Home";
import WhyPage from "../pages/About/why";
import HowPage from "../pages/About/how";
import WhatPage from "../pages/About/what";
import AiPage from "../pages/ai";
import CustomPage from "../pages/customsaas";
import EngagementPage from "../pages/engagement";
import LoginPage from "../pages/Login";
import CTOPage from "../pages/ctoserviceportal";
import VerificationPage from "../pages/verification";
import ForgotPasswordPage from "../pages/forgotpassword";
// import PasswordVerificationPage from '../pages/forgotpassword';
import CreatePasswordPage from "../pages/createpassword";
import BasicInfoPage from "../pages/Registration/basicinfo";
import Survey1 from "../pages/Registration/questionnaireone";
import Survey2 from "../pages/Registration/questionnairetwo";
import PasswordCreation from "../pages/Registration/passwordcreation";
import Congrats from "../pages/Registration/congrats";
import Privacy from "../pages/privacy";
import Survey from "../pages/Survey";

const store = createStore(reducers, applyMiddleware(thunk, logger));

const _ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
};

const ScrollToTop = withRouter(_ScrollToTop);

const Routing = () => {
  useEffect(() => {
    window.onpageshow = () => {
      document.getElementById("preload").style.display = "none";
    };
    window.onload = () => {
      document.getElementById("preload").style.display = "none";
    };
  });

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/why" component={WhyPage} />
            <Route path="/how" component={HowPage} />
            <Route path="/what" component={WhatPage} />
            <Route path="/ai" component={AiPage} />
            <Route path="/customsaas" component={CustomPage} />
            <Route path="/engagement" component={EngagementPage} />
            <Route path="/cto" component={CTOPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/verify/:type" component={VerificationPage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/privacy-policy" component={Privacy} />
            {/* <Route path="/password-verification" component={PasswordVerificationPage} /> */}
            <Route path="/create-password" component={CreatePasswordPage} />
            <Route path="/basic-info" component={BasicInfoPage} />
            <Route path="/questionnaire-one" component={Survey1} />
            <Route path="/questionnaire-two" component={Survey2} />
            <Route path="/password-creation" component={PasswordCreation} />
            <Route path="/congrats" component={Congrats} />
            <Route path="/survey" component={Survey} />
            <Route path="/freelance-info" component={Survey} />
            <Redirect to={"/"} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default Routing;
