import React from 'react';

import site_logo_dark from '../assets/images/site_logo_dark.png';
import { FaChevronLeft } from "react-icons/fa";

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';

import { handleForget } from '../actions/forgot-password';
import { CONSTANTS } from '../constants';

const ForgotSchema = Yup.object().shape({
    phone_number: Yup.string().required('Mobile number is required').matches(CONSTANTS.REGEX.PHONE, 'Please enter valid mobile number'),
});

class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    handleSubmit(formData) {
        this.setState({ isLoading: true });
        this.props.handleForget(formData).then((validUser) => {
            this.setState({ isLoading: false });
            if (validUser) {
                this.props.history.push(`verify/${CONSTANTS.PAGE_TYPE.FORGOT}`);
            } else {
                this.props.alert.show(this.props.userData.message);
            }
        });
    }

    render() {
        return (
            <div className="Login-page verify-page forgot-password-page password-verification">
                <div className="row no-gutters">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row no-gutters">
                            <div className="d-flex bank-arrow-position">
                                <Link to={'/'} className="back-link">
                                    <FaChevronLeft className="arrow" /><span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters d-flex flex-column card forgot-password-card">
                            <div className="card-body">
                                <img src={site_logo_dark} alt="site logo" className="login-page-logo" />
                                <p className="please-login-line">Please enter your mobile number.</p>
                                <Formik
                                    initialValues={{ phone_number: "" }}
                                    validationSchema={ForgotSchema}
                                    onSubmit={values => {
                                        // same shape as initial values
                                        console.log(values);
                                        this.handleSubmit(values);
                                    }}
                                >
                                    {({ handleSubmit, errors, touched, handleChange, handleBlur }) => (
                                        <form className="login-form" onSubmit={handleSubmit}>
                                            <div className="form-group field_cont">
                                                <label htmlFor="phone_number" className="form-label">Mobile Number</label>
                                                <input type="number" name="phone_number" placeholder="Enter mobile number"
                                                    onChange={handleChange} onBlur={handleBlur} pattern="[0-9]*"
                                                    className={`form-control ${touched.phone_number && errors.phone_number ? 'invalid' : ''}`}
                                                />
                                                {touched.phone_number && errors.phone_number ?
                                                    <span className="error_msg">{errors.phone_number}</span>
                                                    : null
                                                }
                                            </div>
                                            <button type="submit" className="btn" disabled={this.state.isLoading}>
                                                {this.state.isLoading ?
                                                    <div className="spinner-grow spinner-grow-sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    : 
                                                    <span>CONTINUE</span>
                                                }
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="Banner d-none d-md-block d-lg-block d-xl-block">
                            <div className="content-background">
                                <div className="banner-content">
                                    <h2 className="banner-heading">Suspendisse ut sapien id neque.</h2>
                                    <div className="blueDash"></div>
                                    <p className="banner-tagline">
                                        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.forgotData
});

const mapActionsToProps = {
    handleForget
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(ForgotPasswordPage)));
