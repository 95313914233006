import React from 'react';

import site_logo_dark from '../assets/images/site_logo_dark.png';
import { FaChevronLeft } from "react-icons/fa";

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { resendOTP, verifyUser } from '../actions/verify';
import { CONSTANTS } from '../constants';
import { storeInStorage } from '../common/service';
import { API_ENDPOINTS, AUTH_HEADER_KEY, PLAIN_HEADERS } from '../constants/api-endpoints';
import { callSurveyData } from '../actions/home';
import { filledSurveyPartOne, filledSurveyPartTwo } from '../actions/survey';

const VerifySchema = Yup.object().shape({
    verification_code: Yup.string().required('Code is required').matches(CONSTANTS.REGEX.CODE, 'Please enter valid code')
});

class VerificationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pageType: '',
            reSending: false
        }
    }

    componentDidMount() {
        var paramsData = this.props.match.params;
        if (paramsData && paramsData.type) {
            this.setState({ pageType: paramsData.type });

            if (paramsData.type === CONSTANTS.PAGE_TYPE.LOGIN) {
                if (!(this.props.userData.info && this.props.userData.info.phone_number)) {
                    this.props.history.push('/login');
                }
            } else if (paramsData.type === CONSTANTS.PAGE_TYPE.FORGOT) {
                if (!(this.props.forgotData.info && this.props.forgotData.info.phone_number)) {
                    this.props.history.push('/login');
                }
            } else {
                this.props.history.push('/');
            }
        } else {
            this.props.history.push('/');
        }
    }

    handleVerify(formData) {
        this.setState({ isLoading: true });
        var url = '';
        var userId = '';
        var queryParams = '';

        if (this.state.pageType === CONSTANTS.PAGE_TYPE.LOGIN) {
            url = API_ENDPOINTS.VERIFY;
            userId = this.props.userData.info.user_id;
            queryParams = `id=${userId}&verification_code=${formData.verification_code}`
        } else {
            url = API_ENDPOINTS.VERIFYOTP;
            userId = this.props.forgotData.info.user_id;
            queryParams = `id=${userId}&otp=${formData.verification_code}`
        }

        this.props.verifyUser(queryParams, url).then((validUser) => {
            if (validUser) {
                if (this.state.pageType === CONSTANTS.PAGE_TYPE.FORGOT) {
                    this.props.history.push('/create-password');
                } else {
                    storeInStorage(CONSTANTS.STORAGE_KEYS.USER_INFO, JSON.stringify(this.props.userData.info));
                    var headers = PLAIN_HEADERS;
                    headers[AUTH_HEADER_KEY] = `Bearer ${this.props.userData.info.authToken}`;

                    this.props.callSurveyData(`user_id=${userId}`, headers).then((retrieved) => {
                        this.setState({ isLoading: false });
                        if (retrieved) {
                            var temp = this.props.surveyData.filter((item) => {
                                return item.answer;
                            })
                            if (temp.length === CONSTANTS.TOTAL_QUESTIONS) {
                                this.props.history.push('/');
                            } else if (temp.length < (CONSTANTS.TOTAL_QUESTIONS / 2)) {
                                this.processSurveyData(temp, '1');
                                this.props.history.push('/questionnaire-one');
                            } else {
                                this.processSurveyData(temp, '2');
                                this.props.history.push('/questionnaire-two');
                            }
                        } else {
                            this.props.history.push('/');
                        }
                    });
                }
            } else {
                this.props.alert.show(this.props.verifyData.message);
                this.setState({ isLoading: false });
            }
        });
    }

    handleResend() {
        this.setState({ reSending: true });
        var url = '';
        var userId = '';

        if (this.state.pageType === CONSTANTS.PAGE_TYPE.LOGIN) {
            url = API_ENDPOINTS.RESEND;
            userId = this.props.userData.info.user_id;
        } else {
            url = API_ENDPOINTS.RESEND_FORGOT;
            userId = this.props.forgotData.info.user_id;
        }

        this.props.resendOTP(`id=${userId}`, url).then(() => {
            this.setState({ reSending: false });
            this.props.alert.show(this.props.verifyData.message);
        });
    }

    processSurveyData(data, part) {
        var surveyDetails = data.map((question) => {
            var options = question.answer.split(',');
            return { ...question, options };
        });

        var stateData = [];

        if (part === '1') {
            stateData = CONSTANTS.QUESTIONS.PART_ONE;
        } else {
            stateData = [...CONSTANTS.QUESTIONS.PART_ONE, ...CONSTANTS.QUESTIONS.PART_TWO];
        }
        surveyDetails.forEach((question) => {
            stateData[question.que_index].options.forEach((option, index) => {
                var extraData = '';
                var tempIndex = question.options.findIndex((item) => {
                    var tempVal = item.split('|');
                    
                    if(tempVal[0] === 'Other'){
                        extraData = tempVal[1] ? tempVal[1] : '';
                    }
                    return tempVal[0] === option.value;
                });
                if (tempIndex !== -1) {
                    stateData[question.que_index].options[index].isChecked = true;
                    if(stateData[question.que_index].options[index].value === 'Other'){
                        stateData[question.que_index].options[index].extra = extraData
                    }
                }
            })
        });

        if (part === '1') {
            this.props.filledSurveyPartOne(stateData.slice(0, CONSTANTS.TOTAL_QUESTIONS / 2));
        } else {
            this.props.filledSurveyPartTwo(stateData.slice(CONSTANTS.TOTAL_QUESTIONS / 2));
        }
    }

    render() {
        const { phone_number } = this.state.pageType === CONSTANTS.PAGE_TYPE.LOGIN ? this.props.userData.info : this.props.forgotData.info;
        return (
            <div className="Login-page verify-page forgot-password-page">
                <div className="row no-gutters">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row no-gutters">
                            <div className="d-flex bank-arrow-position">
                                <Link to={'/'} className="back-link">
                                    <FaChevronLeft className="arrow" /><span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters d-flex flex-column card">
                            <div className="card-body">
                                <img src={site_logo_dark} alt="site logo" className="login-page-logo" />
                                {
                                    this.state.pageType === CONSTANTS.PAGE_TYPE.FORGOT ?
                                        <span className="password-reset-label">Password Reset</span>
                                        : null
                                }
                                <h5 className="verification-label">2- Step Verification</h5>
                                <p className="please-login-line">
                                    A text message with your code has been sent to: ********
                                    {phone_number && phone_number.toString().substr(7)}
                                </p>
                                <Formik
                                    initialValues={{ verification_code: '' }}
                                    validationSchema={VerifySchema}
                                    onSubmit={values => {
                                        // same shape as initial values
                                        console.log(values);
                                        this.handleVerify(values);
                                    }}
                                >
                                    {({ handleSubmit, errors, touched, handleChange, handleBlur }) => (
                                        <form className="login-form" onSubmit={handleSubmit}>
                                            <div className="form-group field_cont">
                                                <label htmlFor="verification_code" className="form-label">Code</label>
                                                <input type="number" placeholder="Enter your code"
                                                    onChange={handleChange} onBlur={handleBlur} name="verification_code"
                                                    className={`form-control ${touched.verification_code && errors.verification_code ? 'invalid' : ''}`}
                                                />
                                                {touched.verification_code && errors.verification_code ?
                                                    <span className="error_msg">{errors.verification_code}</span>
                                                    : null
                                                }
                                            </div>
                                            <button type="submit" className="btn" disabled={this.state.isLoading}>
                                                {this.state.isLoading ?
                                                    <div className="spinner-grow spinner-grow-sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <span>SIGN IN TO YOUR ACCOUNT</span>
                                                }
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                                <div className="sign-up-link">
                                    <div className="d-flex align-items-baseline justify-content-center">
                                        SMS didn't come?&nbsp;                                       
                                        <button className="sign-up btn btn-link p-0"
                                            disabled={this.state.reSending} onClick={() => { this.handleResend() }}
                                        >Send again.</button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="Banner d-none d-md-block d-lg-block d-xl-block">
                            <div className="content-background">
                                <div className="banner-content">
                                    <h2 className="banner-heading">Suspendisse ut sapien id neque.</h2>
                                    <div className="blueDash"></div>
                                    <p className="banner-tagline">
                                        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.loginData,
    forgotData: state.forgotData,
    verifyData: state.verifyData,
    surveyData: state.homeData.surveyData
});

const mapActionsToProps = {
    verifyUser,
    resendOTP,
    callSurveyData,
    filledSurveyPartOne,
    filledSurveyPartTwo
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withAlert()(VerificationPage)));
