import { combineReducers } from 'redux';

import LoginReducer from './login';
import ForgotReducer from './forgot-password';
import VerifyReducer from './verify';
import basicInfoReducer from './basic-info';
import homeReducer from './home';
import surveyReducer from './survey';

// import all reducers here
const rootReducers = combineReducers({
    // combine reducers here
    loginData: LoginReducer,
    forgotData: ForgotReducer,
    verifyData: VerifyReducer,
    basicInfoData: basicInfoReducer,
    homeData: homeReducer,
    surveyData: surveyReducer
});

export default rootReducers;