import React from 'react';  
import siteLogo from '../../assets/images/site_logo_light.svg';
import backArrow from '../../assets/images/back-arrow.svg';
import RightTick from '../../assets/images/right-tick.svg';  
import { Link, withRouter } from 'react-router-dom';

const Congrats = (props) => {
    return (
        <div className="BasicIngo-Page Congrats-Page">   
            <div className="row no-gutters">
                <div className="col-md-6 dark-bckground d-none d-md-block d-lg-block d-xl-block">
                    <img src={ siteLogo } alt="" className="site-logo"/>
                    <div className="blue-longdash"></div>
                    <h3 className="trustedDev-register-label">Congratulations. All Setup!</h3>
                    <div className="header-Banner"></div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                    <div className="card basic-info-card">
                        <nav className="navbar navbar-dark"> 
                            <div className="d-flex progress-bar-wrapper"> 
                                <Link to={'/'} className="header-text">
                                    <img src={ backArrow } alt="" className="back-arrow" /> <span className="d-none d-md-block d-lg-block d-xl-block">Back to site</span>
                                </Link>  
                                <div className="d-flex">
                                    <div className="">
                                        <label className="progress-bar-label active-label">Basic info</label>
                                        <div className="progress progress-one">
                                            <div className="progress-bar progress-bar-active" role="progressbar" style={{width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="progress-bar-label active-label">Survey 1/2</label>
                                        <div className="progress progress-two">
                                            <div className="progress-bar progress-bar-active" role="progressbar" style={{width: "100%" }}  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="progress-bar-label active-label">Survey 2/2</label>
                                        <div className="progress progress-three">
                                            <div className="progress-bar progress-bar-active" role="progressbar" style={{width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div> 
                                        <label className="progress-bar-label last-progress-bar"></label>
                                        <div className="progress progress-four">
                                            <div className="progress-bar progress-bar-active" role="progressbar" style={{width: "100%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>  
                        <div className="card-body">
                            <img src={ RightTick } alt="" className="right-tick" />
                            <h3 className="congrats-headline">Congratulations, welcome aboard! </h3>
                            <p className="check-mail-label">Check your <u>mailbox</u> to finalize your registration.</p>
                            <div className="details-wrapper">
                                <p>Any questions? <a href="#/" className="contactUs-link">Contact Us:</a></p>
                                <div><a className="mb-0 phone-number" href="tel:4019693773">401-969-3773</a></div>
                                <div><a href="mailto:support@trusteddev.ai" className="mailid">support@trusteddev.ai</a></div> 
                            </div>
                        </div> 
                        <div className="card-footer"> 
                            <button type="button" className="btn continue-btn gray-btn" onClick={() => {
                                props.history.push('/');
                            }}>BACK TO SITE</button> 
                            <button type="button" className="btn continue-btn login-btn" onClick={() => {
                                props.history.push('/login');
                            }}>LOGIN</button>                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Congrats);
