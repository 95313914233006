import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import navbarTwitter from '../assets/images/navbar-twitter.svg';
// import navbarFacebook from '../assets/images/navbar-facebook.svg';
// import navbarLinkedIn from '../assets/images/navbar-linkedIn.svg';

class SubNavigation extends React.Component {

    render() {
        const pathUrl = window.location.hash;

        return (
            <div className="">
                <nav className="navbar navbar-expand-md font12 sub_nav collapse navbar-collapse sticky" id="navbarSupportedContent">
                    <div className="container sub-menu-width">
                        <div className="row no-gutters w-100 nav-row d-sm-flex d-md-none d-lg-none d-xl-none">
                            <div className="col-6 col-sm-6 col-md-6">

                                <Link
                                    className="nav-link hire_button btn font10 font-weight600 btn-sm capital_letter"
                                    to={{
                                        pathname: "/freelance-info",
                                        state: { id: new Date().valueOf() }
                                    }}>Hire Top 1% Developers</Link>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6">
                                <div className="nav-vertical-line">
                                    <div className="d-flex flex-column text-center">
                                        {this.props.isUserLoggedIn ?
                                            <div className="d-flex align-items-baseline justify-content-center font14 login-link-font">
                                                <span className="">{this.props.userInfo && `${this.props.userInfo.first_name} ${this.props.userInfo.last_name}`}&nbsp;</span>
                                                <button className="btn btn-link font-weight600 text_underline color_color1" onClick={() => {
                                                    this.props.logOutUser();
                                                }}> Log out</button>
                                            </div>
                                            :
                                            <>
                                                <span className="already-member">Already a Member?</span>
                                                <Link to={'login'} className="login-link">Log in</Link>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to={''}>
                                    <span className={pathUrl === "#/" ? "active_nav" : ""}>Home</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'why'}>
                                    <span className={pathUrl === "#/why" || pathUrl === "#/how" || pathUrl === "#/what" ? "active_nav" : ""}>Why Us?</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'ai'}>
                                    <span className={pathUrl === "#/ai" ? "active_nav" : ""}>AI and Vetted Software Engineers</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'engagement'}>
                                    <span className={pathUrl === "#/engagement" ? "active_nav" : ""}>Our Engagement Model</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'customsaas'}>
                                    <span className={pathUrl === "#/customsaas" ? "active_nav" : ""}>Enterprise Software Development</span>
                                </Link>
                            </li>
                        </ul>
                        {/* <div className="row no-gutters w-100 d-sm-block d-md-none d-lg-none d-xl-none">
                        <div className="col-md-2 d-flex align-items-center justify-content-center my-5"> 
                            <div className="mx-2"> 
                                <a href="/#">
                                    <img src={ navbarTwitter } className="social-media" alt="" />
                                </a>
                            </div>
                            <div className="mx-2">
                                <a href="/#">
                                    <img src={ navbarFacebook } className="social-media" alt="" />
                                </a>
                            </div>
                            <div className="mx-2">
                                <a href="/#">
                                    <img src={ navbarLinkedIn } className="social-media" alt="" />
                                </a>
                            </div>
                        </div>  
                    </div> */}
                        <Link
                            className="nav-link d-none d-md-block d-lg-block d-xl-block hire_button btn font10 font-weight600 btn-sm capital_letter"
                            to={{
                                pathname: "/freelance-info",
                                state: { id: new Date().valueOf() }
                            }}>Hire Top 1% Developers</Link>
                    </div>
                </nav>
            </div>
        )
    }
}


export default withRouter(SubNavigation);