import { apiCall } from "../common/NetworkCalls";
import { API_ENDPOINTS } from "../constants/api-endpoints";
import { MESSAGES } from "../constants/messages";

export const RETRIEVED_SURVEY_DATA = 'retrieved_survey_data';
export const FAILED_TO_RETRIEVE_SURVEY_DATA = 'failed_to_retrieve_survey_data';

function getSurveyData(data){
    return {
        type: RETRIEVED_SURVEY_DATA, 
        payload: data
    }
}

function failedToGetSurveyData(message){
    return {
        type: FAILED_TO_RETRIEVE_SURVEY_DATA, 
        payload: message
    }
}

export function callSurveyData(queryParams, headers){
    return (dispatch) => {
        return apiCall(`${API_ENDPOINTS.GET_SURVEY_DATA}?${queryParams}`, 'GET', '', headers).then((response) => {
            if(response.status.toString() === '200'){
                dispatch(getSurveyData(response.data));
                return true;
            }else{
                dispatch(failedToGetSurveyData(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(failedToGetSurveyData(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}