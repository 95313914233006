export const ENCRYPTION_KEY = 'trusted_dev_encrypt_key';

export const PLAIN_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export const AUTH_HEADER_KEY = 'Authorization';

const BASE_URL = 'https://dev-api.trusteddev.ai';

const MODULE = {
    USER: '/user/api'
}

export const API_ENDPOINTS = {
    LOGIN: `${BASE_URL}${MODULE.USER}/userLogin`,
    VERIFY: `${BASE_URL}${MODULE.USER}/verifyOTP`,
    FORGOT: `${BASE_URL}${MODULE.USER}/forgotPassword`,
    VERIFYOTP: `${BASE_URL}${MODULE.USER}/verifyForgotPasswordOTP`,
    RESET: `${BASE_URL}${MODULE.USER}/resetPassword`,
    RESEND: `${BASE_URL}${MODULE.USER}/resendOTP`,
    RESEND_FORGOT: `${BASE_URL}${MODULE.USER}/resendForgotPasswordOTP`,
    REGISTER: `${BASE_URL}${MODULE.USER}/website/userRegister`,
    LOG_DETAILS: `${BASE_URL}${MODULE.USER}/Log`,
    UPDATE_DETAILS: `${BASE_URL}${MODULE.USER}/updatedetails`,
    SURVEY: `${BASE_URL}${MODULE.USER}/survey`,
    LOGOUT: `${BASE_URL}${MODULE.USER}/logout`,
    GET_SURVEY_DATA: `${BASE_URL}${MODULE.USER}/getSurveyData`
};