import { apiCall } from "../common/NetworkCalls";
import { API_ENDPOINTS } from "../constants/api-endpoints";
import { MESSAGES } from "../constants/messages";

export const USER_REGISTERED = 'user_registered';
export const USER_REGISTRATION_FAILED = 'user_registration_failed';
export const LOGGED_USER_DETAIL = 'logged_user_detail';
export const UPDATED_USER_DETAIL = 'updated_user_detail';
export const RESET_USER_FORM = 'reset_user_form';

function userRegistered(info){
    return {
        type: USER_REGISTERED,
        payload: info
    };
}

function userRegistrationFailed(message){
    return {
        type: USER_REGISTRATION_FAILED, 
        payload: message
    };
}

function loggedUserDetail(info){
    return {
        type: LOGGED_USER_DETAIL,
        payload: info
    };
}

export function updatedUserDetail(data){
    return {
        type: UPDATED_USER_DETAIL, 
        payload: data
    };
}

export function resetUserForm(){
    return {
        type: RESET_USER_FORM,
        payload: ''
    };
}

export function handleRegistration(reqData){
    return (dispatch) => {
        console.log(API_ENDPOINTS.REGISTER, 'POST', reqData)
        return apiCall(API_ENDPOINTS.REGISTER, 'POST', reqData).then((response) => {
            if(response.statusCode.toString() === '200'){
                dispatch(userRegistered(response.data));
                return true;
            }else{
                dispatch(userRegistrationFailed(response.customMessage));
                return false;
            }
        }).catch((error) => {
            dispatch(userRegistrationFailed(MESSAGES.SOMETHING_WRONG));
            return false;
        });
    };
}

export function handleLogDetails(reqData){
    return (dispatch) => {
        return apiCall(API_ENDPOINTS.LOG_DETAILS, 'POST', reqData).then((response) => {
            if(response.status.toString() === '200'){
                dispatch(loggedUserDetail(response.data));
                return true;
            }else{
                return false;
            }
        }).catch((error) => {
            return false;
        });
    };
}