export const CONSTANTS = {
    PAGE_TYPE: {
        LOGIN: 'login',
        FORGOT: 'forgot'
    },
    STORAGE_KEYS: {
        USER_INFO: 'user_info',
    },
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/,
    REGEX: {
        NAME: /^[a-zA-Z]*$/,
        PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?~+\\/'^:,(){}\[\]\-_.])[A-Za-z\d#$@!%&*?~+\\/'^:,(){}\[\]\-_.]{8,}$/,
        PHONE: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
        URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
        CODE: /^[0-9]*$/
    },
    TOTAL_QUESTIONS: 10,
    QUESTIONS: {
        PART_ONE: [
            {
                que_text: 'What type of information technology resource are you currently hiring for?',
                options: [
                    { value: 'Front-end Developer', isChecked: false },
                    { value: 'Back-end Developer', isChecked: false },
                    { value: 'Cyber Security Engineer', isChecked: false },
                    { value: 'Salesforce Developer', isChecked: false },
                    { value: 'CTO as a Service', isChecked: false },
                    { value: 'Other', isChecked: false, extra: '' },
                    { value: 'I don’t know', isChecked: false }
                ],
                que_index: 0,
                type: 'checkbox'
            },
            {
                que_text: 'Which of the following communication styles do you prefer for members of your team?',
                options: [
                    { value: 'Reserved', isChecked: false },
                    { value: 'Direct', isChecked: false },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 1,
                type: 'radio'
            },
            {
                que_text: 'What type of team member best describes one that aligns with your company culture?',
                options: [
                    { value: 'Enjoys structure and rules', isChecked: false },
                    { value: 'Flexible and adaptable', isChecked: false },
                    { value: 'Other', isChecked: false, extra: '' },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 2,
                type: 'radio'
            },
            {
                que_text: 'What type of team member demeanor aligns best with your company culture?',
                options: [
                    { value: 'Relaxed', isChecked: false },
                    { value: 'Intense', isChecked: false },
                    { value: 'Other', isChecked: false, extra: '' },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 3,
                type: 'checkbox'
            },
            {
                que_text: 'Are you interested in remote software engineers or on site software engineers only?',
                options: [
                    { value: 'I am ok with team members working remote 100% of the time', isChecked: false },
                    { value: 'I am ok with team members working remote on occasion', isChecked: false },
                    { value: 'All work must be performed on site', isChecked: false },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 4,
                type: 'checkbox'
            },
        ],
        PART_TWO: [
            {
                que_text: 'How would you describe your current team’s daily attitude towards work?',
                options: [
                    { value: 'Optimistic', isChecked: false },
                    { value: 'Skeptical', isChecked: false },
                    { value: 'Other', isChecked: false, extra: '' },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 5,
                type: 'radio'
            },
            {
                que_text: 'How important is collaboration to your existing team?',
                options: [
                    { value: 'Very important', isChecked: false },
                    { value: 'Important', isChecked: false },
                    { value: 'Unimportant', isChecked: false },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 6,
                type: 'radio'
            },
            {
                que_text: 'How do you prefer team members approach conflict resolution?',
                options: [
                    { value: 'Approach and communicate the problem directly with leadership', isChecked: false },
                    { value: 'Solve the conflict on their own', isChecked: false },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 7,
                type: 'checkbox'
            },
            {
                que_text: 'How many software engineers are you currently hiring to augment your existing team?',
                options: [
                    { value: '1', isChecked: false },
                    { value: '1-3', isChecked: false },
                    { value: '3-5', isChecked: false },
                    { value: '5+', isChecked: false }
                ],
                que_index: 8,
                type: 'radio'
            },
            {
                que_text: 'Do you prefer your team members to be people oriented, task oriented or both?',
                options: [
                    { value: 'People oriented', isChecked: false },
                    { value: 'Task oriented', isChecked: false },
                    { value: 'Both', isChecked: false },
                    { value: 'Other', isChecked: false, extra: '' },
                    { value: 'No preference', isChecked: false }
                ],
                que_index: 9,
                type: 'radio'
            },
        ]
    }
}