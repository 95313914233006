import { apiCall } from "../common/NetworkCalls";
import { MESSAGES } from "../constants/messages";

export const LOGIN_VERIFIED = 'login_verified';
export const LOGIN_FAILED_TO_VERIFY = 'login_failed_to_verify';
export const OTP_RESENT = 'otp_resent';

function loginVerified(info){
    return {
        type: LOGIN_VERIFIED, 
        payload: info
    }
}

function loginFailedToVerify(message){
    return {
        type: LOGIN_FAILED_TO_VERIFY, 
        payload: message
    }
}

function otpResent(message){
    return {
        type: OTP_RESENT, 
        payload: message
    }
}

export function verifyUser(queryParams, url){
    return (dispatch) => {
        return apiCall(`${url}?${queryParams}`, 'GET').then((response) => {
            if(response.status.toString() === '200'){
                dispatch(loginVerified(response.data));
                return true;
            }else{
                dispatch(loginFailedToVerify(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(loginFailedToVerify(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}

export function resendOTP(queryParams, url){
    return (dispatch) => {
        return apiCall(`${url}?${queryParams}`, 'GET').then((response) => {
            if(response.status.toString() === '200'){
                dispatch(otpResent(response.message));
                return true;
            }else{
                dispatch(otpResent(response.message));
                return false;
            }
        }).catch((error) => {
            dispatch(otpResent(MESSAGES.SOMETHING_WRONG));
            return false;
        })
    }
}